import React, { useState, useMemo, useEffect } from 'react';
import { COLORS } from '../../../utils/colors';
import { AppDropDown } from '../AppDropDown/AppDropDown';
import { useAppSelector, useAppDispatch } from '../../../redux/store';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import {
  Job,
  LienTemplate,
  UserLinkApiResponse,
  LienApiPostBody,
  ReleaseFieldData,
  FromFieldTypeReleaseTemplate,
  ReleaseTemplateField,
  formatMoney,
  PDFFieldReleaseTemplate,
  GroupedLienAutoRelease,
} from '@dill/dill-shared';
import {
  populatePagesPdfWithFieldsData,
  removePDFFields,
  structureTemplateNewFieldFromTypePDFData,
  structureTemplateNonNewFieldFromTypePDFData,
} from '../../../utils/helpers';
import './AppCreateRealease.scss';
import moment from 'moment';
import { Plus } from 'react-feather';
import { AppNewJobModal } from '../AppNewJobModal/AppNewJobModal';
import { twMerge } from 'tailwind-merge';
import AppDatePicker from '../AppDatePicker/AppDatePicker';
import { AppInputField } from '../AppInputField/AppInputField';
import { AppDropDown2 } from '../AppDropDown2/AppDropDown2';
import { getJobs } from '../../../redux/services/jobsService';
import { CaretDown } from 'phosphor-react';
import { useParams } from 'react-router-dom';
import { useParamMainBuyerId } from '../../../utils/hooks/useParamMainBuyerId';

export const AppCreateRealease = ({
  paymentAmount,
  onCreateRelease,
  selectedRelease,
  groupedInvoiceReleases,
  releaseErrors,
}: {
  paymentAmount: number;
  onCreateRelease: (
    data: {
      [x: string]: {
        job: Job;
        originalJob: Job;
        template: LienTemplate | null;
        currentPdfUrl: string;
        originalPdfUrl: string;
        subSupplierPosition: number | null;
        amount: number;
        formFields: { [x: string]: ReleaseFieldData };
        templateFields: ReleaseTemplateField[] | null;
      };
    },
    vendorDetails: {
      [x: string]: {
        address: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
      };
    },
    releaseArray: LienApiPostBody[]
  ) => void;
  releaseErrors: {
    [key: string]: { [key: string]: string };
  };
  selectedRelease: {
    [x: string]: {
      job: Job;
      originalJob: Job;
      template: LienTemplate | null;
      currentPdfUrl: string;
      originalPdfUrl: string;
      subSupplierPosition: number | null;
      amount: number;
      formFields: { [x: string]: ReleaseFieldData };
      templateFields: ReleaseTemplateField[] | null;
    };
  };
  groupedInvoiceReleases: GroupedLienAutoRelease[];
}) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const { jobs } = useAppSelector((state) => state.jobs);
  const { subSuppliers } = useAppSelector((state) => state.subSuppliers);
  const { user } = useAppSelector((state) => state.auth);
  const { selectedMainBuyer } = useAppSelector((state) => state.buyers);

  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [selectedSupplierUserPosition, setSelectedSupplierUserPosition] = useState<number | null>(
    null
  );
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [openDropDown, setOpenDropDown] = useState<number | null>(null);
  const [currentReleasePosition, setCurrentReleasePosition] = useState<number | null>(null);
  const [openNewJob, setOpenNewJob] = useState(false);
  const [editJob, setEditJob] = useState(false);
  const [releaseGroupDetails, setReleaseGroupDetails] = useState<{
    [x: string]: {
      job: Job;
      originalJob: Job;
      template: LienTemplate | null;
      currentPdfUrl: string;
      originalPdfUrl: string;
      subSupplierPosition: number | null;
      amount: number;
      formFields: { [x: string]: ReleaseFieldData };
      templateFields: ReleaseTemplateField[] | null;
    };
  }>({});
  const [vendorDetail, setVendorDetail] = useState<{
    [x: string]: {
      address: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
    };
  }>({});
  const [updateAddres, setUpdateAddress] = useState(false);

  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageLabel } = pageNavigationPluginInstance;

  const templates = useMemo(() => {
    if (lienTemplates.length > 0) {
      const processedLienTemplates = lienTemplates.filter(
        (template) => template.status === 'processed'
      );
      return processedLienTemplates;
    }
    return [];
  }, [lienTemplates]);

  const subSupplierUsers = useMemo(() => {
    if (subSuppliers) {
      return subSuppliers
        .filter((subsup) => !subsup.archivedState?.includes('BUYER_ARCHIVED'))
        .reduce(
          (
            prev: { subSupplierName: string; subSupplierId: string; user: UserLinkApiResponse }[],
            curr
          ) => {
            return [
              ...prev,
              ...curr.users
                .filter((userLink) => {
                  if (
                    userLink.subUser &&
                    userLink.subUser.roles &&
                    userLink.subUser.roles.includes('REVIEWER')
                  ) {
                    return true;
                  }
                  return !userLink?.subUser?.roles;
                })
                .map((userLink) => {
                  return { subSupplierId: curr.id, subSupplierName: curr.name, user: userLink };
                }),
            ];
          },
          []
        );
    }
    return [];
  }, [subSuppliers]);

  const hasCityStateZipCode = (temp: LienTemplate | null) => {
    if (temp) {
      const hasAddress = [...temp.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorCityStateZip')
      );
      return hasAddress.length > 0;
    }

    return false;
  };
  const hasVendorAddress = (temp: LienTemplate | null) => {
    if (temp) {
      const hasAddress = [...temp.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorAddress')
      );
      return hasAddress.length > 0;
    }

    return false;
  };

  const hasJobOwner = (job: Job) => {
    if (job) {
      if (!job?.owner || job?.owner === '') {
        return true;
      }
    }
    return false;
  };

  const hasAddressJobCityStateZip = (job: Job) => {
    if (job) {
      if (
        !job?.city ||
        job?.city === '' ||
        !job?.state ||
        job?.state === '' ||
        !job?.zipCode ||
        !job?.address ||
        job?.address === ''
      ) {
        return true;
      }
    }
    return false;
  };

  const populateTemplateFieldData = (
    temp: LienTemplate,
    updateFields: { [x: string]: ReleaseFieldData },
    supplierPosition: number | null
  ) => {
    let data: { [x: string]: ReleaseFieldData } = {};
    let vendorPhone: { [x: string]: ReleaseFieldData } = {};
    temp.formFields.forEach((formField) => {
      if (formField.fromFieldType === 'NEW_FIELD' && formField.whoPopulatesField === 'BUYER') {
        let defaultValue: any = '';
        if (
          formField.fieldType === 'CURRENCY' &&
          formField.defaultValue !== null &&
          formField.defaultValue !== undefined
        ) {
          defaultValue = Number(formField.defaultValue).toFixed(2);
        } else if (formField.defaultValue !== null && formField.defaultValue !== undefined) {
          defaultValue = `${formField.defaultValue}`;
        } else {
          defaultValue = formField.defaultValue;
        }
        data = {
          ...data,
          [formField.id]: {
            isRequired: formField.isFieldRequired,
            value:
              updateFields[formField.id] && updateFields[formField.id].value
                ? updateFields[formField.id].value
                : formField.defaultValue !== null && formField.defaultValue !== undefined
                ? defaultValue
                : '',
            id: formField.id,
            name: formField.fieldName,
            type: formField.fieldType,
          },
        };
      }
    });
    const currentDate = moment();
    const endOfLastMonth = currentDate.subtract(1, 'months').endOf('month').startOf('day');
    const endOfMonthDate = endOfLastMonth.toDate();
    const searchParams = new URLSearchParams(location.search);
    const currentThroughDate = searchParams.get('currentThroughDate');
    let throughDateData: { [x: string]: ReleaseFieldData } = {};
    for (const key of Object.keys(data)) {
      if (key.startsWith('throughDate')) {
        throughDateData = {
          ...throughDateData,
          [key]: {
            ...data[key],
            value: currentThroughDate ? new Date(currentThroughDate) : new Date(endOfMonthDate),
          },
        };
      }
    }
    data = { ...data, ...throughDateData };

    if (supplierPosition !== null) {
      const foundSubSupplier = subSuppliers.find(
        (item) =>
          subSupplierUsers?.[supplierPosition] &&
          item.id === subSupplierUsers?.[supplierPosition].subSupplierId
      );
      if (foundSubSupplier) {
        const foundPhoneInput = temp?.formFields?.find(
          (formFields) =>
            formFields.fromFieldType === 'NEW_FIELD' &&
            formFields.whoPopulatesField === 'BUYER' &&
            formFields.id.startsWith('vendorPhone')
        );
        if (foundPhoneInput) {
          const updateField = data[foundPhoneInput.id];
          if (updateField) {
            vendorPhone = {
              [foundPhoneInput.id]: {
                ...updateField,
                value: foundSubSupplier?.phoneNumber ? `${foundSubSupplier?.phoneNumber}` : '',
              },
            };
          }
        }
      }
    }

    return { ...data, ...vendorPhone };
  };

  const populatePdf = async (fieldDetails: PDFFieldReleaseTemplate[], pdfUrl: string) => {
    const populatedPdfUrl = await populatePagesPdfWithFieldsData({
      fieldDetails,
      url: pdfUrl,
    });
    return populatedPdfUrl;
  };

  const populateAllPdfData = async (
    job: Job,
    template: LienTemplate | null,
    pdfUrl: string,
    supplierPosition: number | null,
    formFields: { [x: string]: ReleaseFieldData } | null
  ) => {
    const specialFieldsPDFData: { fromFieldType: FromFieldTypeReleaseTemplate; dataToUse: any }[] =
      [];

    const dynamicForFieldsPdfData: {
      fromFieldId: string;
      value: any;
    }[] = [];

    const updatedSelectedJob = job;

    if (job) {
      specialFieldsPDFData.push({ fromFieldType: 'JOB_FIELD', dataToUse: updatedSelectedJob });
    }

    if (supplierPosition !== null && subSupplierUsers[supplierPosition]) {
      specialFieldsPDFData.push({
        fromFieldType: 'SUPPLIER_FIELD',
        dataToUse: subSupplierUsers[supplierPosition],
      });
    }

    if (template) {
      specialFieldsPDFData.push({
        fromFieldType: 'USER_BUYER_FIELD',
        dataToUse: selectedMainBuyer,
      });
      const allNewFormFields = { ...formFields };
      Object.keys(allNewFormFields).forEach((formFieldKey) => {
        dynamicForFieldsPdfData.push({
          fromFieldId: formFieldKey,
          value: allNewFormFields[formFieldKey].value,
        });
      });
    }
    let pdfData: PDFFieldReleaseTemplate[] = [];
    if (specialFieldsPDFData.length > 0 && template) {
      const data1 = structureTemplateNonNewFieldFromTypePDFData({
        template: template,
        details: specialFieldsPDFData,
      });
      pdfData = [...pdfData, ...data1];
    }
    if (dynamicForFieldsPdfData.length > 0) {
      const data2 = structureTemplateNewFieldFromTypePDFData({
        template: template,
        details: dynamicForFieldsPdfData,
      });
      pdfData = [...pdfData, ...data2];
    }
    const popultedurl = await populatePdf(pdfData, pdfUrl);
    if (popultedurl) {
      return popultedurl;
    }
    return null;
  };

  const generateTemplateFields = (temp: LienTemplate) => {
    return [...temp.formFields]
      .sort((A, B) => {
        if (
          (A.position === null || A.position === undefined) &&
          B.position !== null &&
          B.position !== undefined
        ) {
          return 1;
        }
        if (
          (B.position === null || B.position === undefined) &&
          A.position !== null &&
          A.position !== undefined
        ) {
          return -1;
        }
        if (
          A.position !== null &&
          A.position !== undefined &&
          B.position !== null &&
          B.position !== undefined &&
          A.position > B?.position
        ) {
          return 1;
        }
        return -1;
      })
      .filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id !== 'paymentAmount' &&
          formFields.id !== 'throughDate' &&
          !formFields.id.startsWith('vendorCityStateZip') &&
          !formFields.id.startsWith('vendorAddress')
      );
  };

  const getFormFields = (
    temp: LienTemplate,
    updateFields: { [x: string]: ReleaseFieldData },
    amount: number
  ) => {
    const requiredThroughDate = temp?.formFields?.find((field) => field?.id === 'throughDate');
    const requiredPaymentAmount = temp?.formFields?.find(
      (field) => field?.id === 'paymentAmount' && field.whoPopulatesField === 'BUYER'
    );
    let data: { [x: string]: ReleaseFieldData } = {};
    temp.formFields.forEach((formField) => {
      if (formField.fromFieldType === 'NEW_FIELD' && formField.whoPopulatesField === 'BUYER') {
        data = {
          ...data,
          [formField.id]: {
            isRequired: formField.isFieldRequired,
            value:
              updateFields[formField.id] && updateFields[formField.id].value
                ? updateFields[formField.id].value
                : formField.defaultValue || '',
            id: formField.id,
            name: formField.fieldName,
            type: formField.fieldType,
          },
        };
      }
    });
    const currentDate = moment();
    const endOfMonth = currentDate.endOf('month').startOf('day');
    const endOfMonthDate = endOfMonth.toDate();
    if (requiredThroughDate && requiredPaymentAmount) {
      return {
        ...data,
        ['throughDate']: {
          name: requiredThroughDate.fieldName,
          id: requiredThroughDate.id,
          isRequired: requiredThroughDate.isFieldRequired,
          type: requiredThroughDate.fieldType,
          value: new Date(endOfMonthDate),
        },
        ['paymentAmount']: {
          name: requiredPaymentAmount.fieldName,
          id: requiredPaymentAmount.id,
          isRequired: requiredPaymentAmount.isFieldRequired,
          type: requiredPaymentAmount.fieldType,
          value: amount,
        },
      };
    } else if (requiredThroughDate) {
      return {
        ...data,
        ['throughDate']: {
          name: requiredThroughDate.fieldName,
          id: requiredThroughDate.id,
          isRequired: requiredThroughDate.isFieldRequired,
          type: requiredThroughDate.fieldType,
          value: new Date(endOfMonthDate),
        },
      };
    } else if (requiredPaymentAmount) {
      return {
        ...data,
        ['paymentAmount']: {
          name: requiredPaymentAmount.fieldName,
          id: requiredPaymentAmount.id,
          isRequired: requiredPaymentAmount.isFieldRequired,
          type: requiredPaymentAmount.fieldType,
          value: amount,
        },
      };
    } else {
      return {
        ...data,
      };
    }
  };

  useEffect(() => {
    const releaseArray: LienApiPostBody[] = [];
    Object.entries(releaseGroupDetails).forEach(([key, value]) => {
      if (value?.subSupplierPosition !== null && value.formFields && selectedMainBuyerId) {
        const selectedUser = subSupplierUsers[value?.subSupplierPosition];
        releaseArray.push({
          jobId: value.job?.id || '',
          notes: `buyerReleases/${selectedMainBuyerId}/${
            subSupplierUsers[value?.subSupplierPosition]?.subSupplierId
          }/${selectedJob?.id}`,
          previewPdfUrl: value.currentPdfUrl,
          recipientId: {
            userId: selectedUser?.user?.user?.id || '',
            subUserId: selectedUser?.user?.subUser?.id || '',
          },
          subSupplierId: selectedUser?.subSupplierId || '',
          templateId: value.template?.id || '',
          emailReminderFrequency: 3,
          dueDate: null,
          releaseAttachments: null,
          status: 'Pending',
          fieldsData: Object.values(value.formFields),
        });
      }
    });
    onCreateRelease(releaseGroupDetails, vendorDetail, releaseArray);
  }, [releaseGroupDetails, vendorDetail]);

  const handleOwnerChange = async (text: string, positon: number) => {
    const releaseselect = releaseGroupDetails[positon];
    const updateJob = { ...releaseselect.job, owner: text };
    const popURL = await populateAllPdfData(
      updateJob,
      releaseselect.template,
      releaseselect.originalPdfUrl,
      releaseselect.subSupplierPosition,
      releaseselect.formFields
    );
    setReleaseGroupDetails({
      ...releaseGroupDetails,
      [positon]: {
        ...releaseselect,
        job: updateJob,
        currentPdfUrl: popURL ?? '',
      },
    });
  };

  const handleJobAddressCityStateZipChange = async (
    text: string,
    positon: number,
    key: 'city' | 'state' | 'zipCode' | 'address'
  ) => {
    const releaseselect = releaseGroupDetails[positon];
    const updateJob = { ...releaseselect.job, [key]: text };
    const popURL = await populateAllPdfData(
      updateJob,
      releaseselect.template,
      releaseselect.originalPdfUrl,
      releaseselect.subSupplierPosition,
      releaseselect.formFields
    );
    setReleaseGroupDetails({
      ...releaseGroupDetails,
      [positon]: {
        ...releaseselect,
        job: updateJob,
        currentPdfUrl: popURL ?? '',
      },
    });
  };

  const handleEditJob = (e: React.MouseEvent<HTMLButtonElement>, job: Job) => {
    e.stopPropagation();
    setEditJob(true);
    setSelectedJob(job);
    setOpenNewJob(true);
  };

  const handleOpen = (id: number) => {
    setOpenDropDown(id);
  };

  const releases = useMemo(() => {
    const updateReleases = [];
    let releaseMap = {};
    if (groupedInvoiceReleases) {
      for (let index = 0; index < groupedInvoiceReleases.length; index++) {
        const invoiceRelease = groupedInvoiceReleases[index];
        const job = jobs.find((foundjob) => foundjob?.quickbooksJobId === invoiceRelease.jobId);
        updateReleases.push({ ...invoiceRelease, jobs: job ? [job] : jobs });
        releaseMap = {
          ...releaseMap,
          [index]: {
            job: job ? job : jobs[0],
            template: null,
            currentPdfUrl: '',
            subSupplierPosition: subSupplierUsers.length > 0 ? 0 : null,
            amount: invoiceRelease.totalAmount,
            formFields: null,
            templateFields: null,
            originalPdfUrl: null,
            originalJob: job ? job : jobs[0],
          },
        };
      }
    }
    setReleaseGroupDetails(releaseMap);
    if (updateReleases.length === 1) {
      setOpenDropDown(0);
    }
    return updateReleases;
  }, [groupedInvoiceReleases, jobs, subSupplierUsers]);

  const originalFormFields = (temp: LienTemplate) => {
    let data: { [x: string]: ReleaseFieldData } = {};
    temp.formFields.forEach((formField) => {
      if (formField.fromFieldType === 'NEW_FIELD' && formField.whoPopulatesField === 'BUYER') {
        data = {
          ...data,
          [formField.id]: {
            isRequired: formField.isFieldRequired,
            value: formField.defaultValue || '',
            id: formField.id,
            name: formField.fieldName,
            type: formField.fieldType,
          },
        };
      }
    });
    return data;
  };

  const handleSelectTemplate = async (temp: LienTemplate, positon: number) => {
    const releaseselect = releaseGroupDetails[positon];
    const selectedPdfUrl = await removePDFFields(temp.originalPdf?.url ?? '');
    const origFormFields = originalFormFields(temp);
    const populatedTemplateFields = populateTemplateFieldData(
      temp,
      origFormFields,
      releaseselect.subSupplierPosition
    );
    const templateFields = generateTemplateFields(temp);
    const formFields = getFormFields(temp, populatedTemplateFields, releaseselect.amount);
    const popURL = await populateAllPdfData(
      releaseselect.job,
      temp,
      selectedPdfUrl ?? '',
      releaseselect.subSupplierPosition,
      formFields
    );
    setReleaseGroupDetails({
      ...releaseGroupDetails,
      [positon]: {
        ...releaseselect,
        template: temp,
        currentPdfUrl: popURL ?? '',
        originalPdfUrl: selectedPdfUrl,
        formFields: formFields,
        templateFields: templateFields,
      },
    });
  };

  const handleSelectJob = async (job: Job, positon: number) => {
    const releaseselect = releaseGroupDetails[positon];
    let popURL = null;
    if (releaseselect.template) {
      popURL = await populateAllPdfData(
        job,
        releaseselect.template,
        releaseselect.originalPdfUrl,
        releaseselect.subSupplierPosition,
        releaseselect.formFields
      );
    }
    setReleaseGroupDetails({
      ...releaseGroupDetails,
      [positon]: { ...releaseselect, job: job, currentPdfUrl: popURL ?? '', originalJob: job },
    });
  };

  const handleVendorDetails = async (
    position: number,
    val: { address: string | null; city: string | null; state: string | null; zip: string | null }
  ) => {
    const releaseselect = releaseGroupDetails[position];
    if (releaseselect.template) {
      const foundAddressInput = [...releaseselect.template.formFields].find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorAddress')
      );
      const foundTemplateInput = [...releaseselect.template.formFields].find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorCityStateZip')
      );
      const updateAddressField = foundAddressInput
        ? releaseselect.formFields[foundAddressInput?.id]
        : undefined;
      const updateVendorCityField = foundTemplateInput
        ? releaseselect.formFields[foundTemplateInput.id]
        : undefined;
      let updateFields = releaseselect.formFields;
      if (updateAddressField) {
        updateFields = {
          ...updateFields,
          [updateAddressField.id]: { ...updateAddressField, value: val.address ?? '' },
        };
      }
      if (updateVendorCityField) {
        const citystatezipcode = `${val.city ? `${val.city}, ` : ''} ${
          val.state ? `${val.state} ` : ''
        } ${val.zip ? `${val.zip}` : ''}`;
        updateFields = {
          ...updateFields,
          [updateVendorCityField.id]: { ...updateVendorCityField, value: citystatezipcode },
        };
      }
      const foundPhoneInput = [...releaseselect.template.formFields].find(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorPhone')
      );
      const updatePhoneField = foundPhoneInput
        ? releaseselect.formFields[foundPhoneInput?.id]
        : undefined;
      if (updatePhoneField && releaseselect.subSupplierPosition) {
        const subSupplierId =
          subSupplierUsers[releaseselect.subSupplierPosition]?.subSupplierId ?? '';
        const foundaddress = subSuppliers.find((supplier) => supplier.id === subSupplierId);
        updateFields = {
          ...updateFields,
          [updatePhoneField.id]: { ...updatePhoneField, value: foundaddress?.phoneNumber ?? '' },
        };
      }
      const populatURL = await populateAllPdfData(
        releaseselect.job,
        releaseselect.template,
        releaseselect.originalPdfUrl,
        releaseselect.subSupplierPosition,
        updateFields
      );
      setReleaseGroupDetails({
        ...releaseGroupDetails,
        [position]: {
          ...releaseselect,
          formFields: updateFields,
          currentPdfUrl: populatURL,
        },
      });
    }
  };

  const handleChangeValue = async (id: string, val: any, positon: number) => {
    const releaseselect = releaseGroupDetails[positon];
    const updateFields = {
      ...releaseselect.formFields,
      [id]: { ...releaseselect.formFields[id], value: val },
    };
    const populatURL = await populateAllPdfData(
      releaseselect.job,
      releaseselect.template,
      releaseselect.originalPdfUrl,
      releaseselect.subSupplierPosition,
      updateFields
    );
    setReleaseGroupDetails({
      ...releaseGroupDetails,
      [positon]: {
        ...releaseselect,
        formFields: updateFields,
        currentPdfUrl: populatURL,
      },
    });
  };

  const handleSelectSupplier = async (item: number, positon: number) => {
    const releaseselect = releaseGroupDetails[positon];
    let popURL = null;
    if (releaseselect.template) {
      popURL = await populateAllPdfData(
        releaseselect.job,
        releaseselect.template,
        releaseselect.originalPdfUrl,
        item,
        releaseselect.formFields
      );
    }
    setReleaseGroupDetails({
      ...releaseGroupDetails,
      [positon]: {
        ...releaseselect,
        currentPdfUrl: popURL ?? '',
        subSupplierPosition: item,
      },
    });
    const subSupplierId = subSupplierUsers[item]?.subSupplierId ?? '';
    const foundaddress = subSuppliers.find((supplier) => supplier.id === subSupplierId);
    if (foundaddress) {
      setVendorDetail({
        ...vendorDetail,
        [positon]: {
          address: foundaddress?.address ?? '',
          city: foundaddress?.city ?? '',
          state: foundaddress?.state ?? '',
          zip: `${foundaddress?.zipCode ?? ''}`,
        },
      });
      handleVendorDetails(positon, {
        address: foundaddress?.address ?? '',
        city: foundaddress?.city ?? '',
        state: foundaddress?.state ?? '',
        zip: `${foundaddress?.zipCode ?? ''}`,
      });
    }
  };

  useEffect(() => {
    if (vendorDetail) {
      const keys = Object.keys(vendorDetail);
      for (let index = 0; index < keys.length; index++) {
        const position = Number(keys[index]);
        handleVendorDetails(position, vendorDetail[position]);
      }
    }
  }, [vendorDetail, updateAddres]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedRelease && Object.keys(selectedRelease).length > 0) {
        const release = Object.assign({}, selectedRelease);

        for (let index = 0; index < groupedInvoiceReleases.length; index++) {
          const groupedInvoiceRelease = groupedInvoiceReleases[index];
          if (release[index]?.formFields?.paymentAmount?.value) {
            release[index].formFields.paymentAmount.value = groupedInvoiceRelease.totalAmount;
          }
          release[index].amount = groupedInvoiceRelease.totalAmount;

          const populatedUrl = await populateAllPdfData(
            release[index].job,
            release[index].template,
            release[index].originalPdfUrl,
            release[index].subSupplierPosition,
            release[index].formFields
          );
          if (populatedUrl) {
            release[index].currentPdfUrl = populatedUrl;
          }
        }

        setReleaseGroupDetails(Object.assign({}, release));
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (currentReleasePosition !== null && selectedJob && jobs) {
      const updatedJob = jobs.find((item) => {
        return item.id === selectedJob.id;
      });
      if (updatedJob) {
        setSelectedJob(updatedJob);
        handleSelectJob(updatedJob, currentReleasePosition);
      }
    }
    return () => {
      setCurrentReleasePosition(null);
    };
  }, [jobs]);

  return (
    <div className="mt-2">
      <AppNewJobModal
        open={openNewJob}
        editMode={editJob}
        handleClose={async () => {
          setOpenNewJob(false);
          setEditJob(false);
          if (selectedMainBuyerId) {
            await dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
          }
        }}
        subSuppliers={subSuppliers}
        selectedJob={editJob && selectedJob ? selectedJob : null}
      />
      <div className="text-lg font-bold mb-2">{`Lien Release Request${
        groupedInvoiceReleases.length > 1 ? 's' : ''
      } – Please Review`}</div>
      {releases.map((release, index) => {
        const currentRelease = releaseGroupDetails[index];
        const errors = releaseErrors?.[index];
        return (
          <div
            className={`flex border-2 ${
              errors && Object.keys(errors)?.length > 0 ? 'ERROR_500-BORDER' : 'GREY_200-BORDER'
            } rounded w-full flex-col mb-2  p-2 ${
              openDropDown === index ? 'cursor-default' : 'cursor-pointer'
            }`}
            key={`auto_releases_${index}`}
            onClick={() => handleOpen(index)}>
            <div className="flex w-full justify-between flex-row">
              <div className="text-base font-bold">{`Lien Release ${
                releases.length === 1 ? '' : index + 1
              }`}</div>
              <CaretDown
                size={24}
                color={COLORS.GREY_600}
                style={{ rotate: openDropDown === index ? '180deg' : '0deg' }}
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  if (openDropDown === index) {
                    setOpenDropDown(null);
                  } else {
                    setOpenDropDown(index);
                  }
                }}
              />
            </div>
            {openDropDown === index && (
              <div className="w-full">
                <div className="flex my-2 flex-wrap">
                  {release.invoices.map((invoice, index4) => (
                    <div
                      key={`auto-release-invoice-${index4}`}
                      className="GREY_100-BG GREY_500-CLR px-4 py-1.5 rounded-full mr-2 mb-2 font-semibold text-xs whitespace-nowrap">{`Invoice #${
                      invoice.invoiceNumber
                    } | ${formatMoney(invoice.amount, 2)}`}</div>
                  ))}
                </div>
                <div className="my-2 relative w-full">
                  <AppDropDown2
                    closeMenu
                    isRequired
                    label="Choose Job"
                    errorText={errors?.job ?? ''}
                    value={releaseGroupDetails[index]?.job?.id}
                    items={[
                      ...release.jobs.map((job) => {
                        return {
                          label: `${job?.number ? `${job.number} - ` : ''}${job.name}`,
                          value: job.id,
                          labelInElement: (
                            <div className="flex px-2 py-3 m-[-5px] items-center justify-between group relative">
                              <div className="text-sm">{`${job?.number ? `${job.number} - ` : ''}${
                                job.name
                              }`}</div>
                              <button
                                onClick={(e) => {
                                  handleEditJob(e, job);
                                  setCurrentReleasePosition(index);
                                }}
                                className="text-sm px-4 py-1 WHITE-BG font-bold TEXT_SECONDARY-CLR rounded absolute hidden group-hover:inline-block right-2">
                                Edit
                              </button>
                            </div>
                          ),
                        };
                      }),
                    ]}
                    valueStyle="text-sm py-0.5"
                    onSelectChange={(item) => {
                      if (item?.value) {
                        const uu = jobs.find((val) => val.id === item.value);
                        if (uu) {
                          handleSelectJob(uu, index);
                        }
                      }
                    }}
                  />
                  <button
                    onClick={() => setOpenNewJob(true)}
                    className={`absolute right-0 flex items-center PRIMARY_500-BORDER border-b ${
                      errors?.job ? 'bottom-[60px]' : 'bottom-11'
                    }`}>
                    <Plus size={12} color={COLORS.PRIMARY_500} />
                    <div className="PRIMARY_500-CLR text-xs">Add New Job</div>
                  </button>
                </div>
                <div className="my-2 flex">
                  <div className="w-full">
                    <AppDropDown
                      isRequired
                      label="Choose Template"
                      errorText={errors?.template ?? ''}
                      value={releaseGroupDetails[index].template?.id ?? ''}
                      items={[
                        ...templates.map((template) => {
                          return {
                            label: template.name,
                            value: template.id,
                          };
                        }),
                      ]}
                      onSelectChange={(item) => {
                        if (item?.value) {
                          const uu = templates.find((val) => val.id === item.value);
                          if (uu) {
                            handleSelectTemplate(uu, index);
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="w-full ml-3">
                    <AppDropDown
                      isRequired
                      label="Choose Supplier Contact"
                      errorText={errors?.supplier ?? ''}
                      value={
                        (releaseGroupDetails[index].subSupplierPosition !== null
                          ? releaseGroupDetails[index].subSupplierPosition
                          : '') + ''
                      }
                      items={[
                        ...subSupplierUsers.map((subSupplierUser, i) => {
                          const userDetails = subSupplierUser.user.subUser
                            ? subSupplierUser.user.subUser
                            : subSupplierUser.user.user;
                          return {
                            label: `${subSupplierUser?.subSupplierName} - ${
                              userDetails?.firstName || ''
                            } ${userDetails?.lastName || ''} `,
                            value: i + '',
                          };
                        }),
                      ]}
                      onSelectChange={(item) => {
                        if (item?.value !== null && item?.value !== undefined) {
                          const uu = subSupplierUsers.find((val, i) => i === parseInt(item?.value));
                          if (uu) {
                            handleSelectSupplier(item?.value, index);
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                {hasVendorAddress(currentRelease.template) && (
                  <div className="w-full flex">
                    <div className="w-full mb-3">
                      <AppInputField
                        errorText={errors?.address ?? ''}
                        label="Vendor Address"
                        isRequired={true}
                        onBlur={() => setUpdateAddress(!updateAddres)}
                        value={vendorDetail?.[index]?.address ?? ''}
                        inputType={'text'}
                        onTextChange={(text) => {
                          setVendorDetail({
                            ...vendorDetail,
                            [index]: {
                              ...vendorDetail[index],
                              address: text,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
                {hasCityStateZipCode(currentRelease.template) && (
                  <div className="w-full flex">
                    <div className="w-full mr-2 mb-3">
                      <AppInputField
                        errorText={errors?.city ?? ''}
                        label="Vendor City"
                        isRequired={true}
                        onBlur={() => setUpdateAddress(!updateAddres)}
                        value={vendorDetail?.[index]?.city ?? ''}
                        inputType={'text'}
                        onTextChange={(text) => {
                          setVendorDetail({
                            ...vendorDetail,
                            [index]: {
                              ...vendorDetail[index],
                              city: text,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="w-full mr-2 mb-3">
                      <AppInputField
                        errorText={errors?.state ?? ''}
                        label="Vendor State"
                        isRequired={true}
                        onBlur={() => setUpdateAddress(!updateAddres)}
                        value={vendorDetail?.[index]?.state ?? ''}
                        inputType={'text'}
                        onTextChange={(text) => {
                          setVendorDetail({
                            ...vendorDetail,
                            [index]: {
                              ...vendorDetail[index],
                              state: text,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="w-full mb-3">
                      <AppInputField
                        errorText={errors?.zipCode ?? ''}
                        label="Vendor Zip"
                        isRequired={true}
                        onBlur={() => setUpdateAddress(!updateAddres)}
                        value={vendorDetail?.[index]?.zip ?? ''}
                        inputType={'number'}
                        onTextChange={(text) => {
                          setVendorDetail({
                            ...vendorDetail,
                            [index]: {
                              ...vendorDetail[index],
                              zip: text,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
                {hasAddressJobCityStateZip(currentRelease.originalJob) && (
                  <div>
                    <div className="w-full my-2 pr-2 mb-3">
                      <AppInputField
                        label="Job Address"
                        inputType="text"
                        value={releaseGroupDetails[index].job.address || ''}
                        isRequired={true}
                        errorText={errors?.address ?? ''}
                        onTextChange={(text) => {
                          handleJobAddressCityStateZipChange(text, index, 'address');
                        }}
                      />
                    </div>
                    <div className="w-full flex">
                      <div className="w-full mr-2 mb-3">
                        <AppInputField
                          errorText={errors?.city ?? ''}
                          label="Job City"
                          isRequired={true}
                          value={releaseGroupDetails[index].job.city || ''}
                          inputType={'text'}
                          onTextChange={(text) => {
                            handleJobAddressCityStateZipChange(text, index, 'city');
                          }}
                        />
                      </div>
                      <div className="w-full mr-2 mb-3">
                        <AppInputField
                          errorText={errors?.state ?? ''}
                          label="Job State"
                          isRequired={true}
                          value={releaseGroupDetails[index].job.state || ''}
                          inputType={'text'}
                          onTextChange={(text) => {
                            handleJobAddressCityStateZipChange(text, index, 'state');
                          }}
                        />
                      </div>
                      <div className="w-full mb-3">
                        <AppInputField
                          errorText={errors?.zipCode ?? ''}
                          label="Job Zip"
                          isRequired={true}
                          value={releaseGroupDetails[index].job.zipCode || ''}
                          inputType={'number'}
                          onTextChange={(text) => {
                            handleJobAddressCityStateZipChange(text, index, 'zipCode');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-wrap ">
                  {hasJobOwner(currentRelease.originalJob) && (
                    <div className="w-1/2 my-2 pr-2">
                      <AppInputField
                        label="Owner"
                        inputType="text"
                        value={releaseGroupDetails[index]?.job?.owner || ''}
                        isRequired={true}
                        errorText={errors?.owner ?? ''}
                        onTextChange={(text) => {
                          handleOwnerChange(text, index);
                        }}
                      />
                    </div>
                  )}
                  {currentRelease.templateFields &&
                    currentRelease.templateFields.length > 0 &&
                    [...currentRelease.templateFields].map((formField, i) => {
                      const {
                        id,
                        fieldType,
                        fieldName,
                        isFieldRequired,
                        suffix,
                        prefix,
                        maxValue,
                        minValue,
                      } = formField;
                      const fieldValue = currentRelease.formFields[id]?.value || '';
                      const index2 = hasJobOwner(currentRelease.originalJob) ? i + 1 : i;
                      return (
                        <div
                          key={index2 + 'oo'}
                          className={twMerge(
                            'w-1/2 my-2',
                            index2 % 2 === 0 ? 'pr-1' : 'pl-1',
                            currentRelease?.template !== null &&
                              index2 ===
                                currentRelease?.template.formFields.filter(
                                  (formFields) =>
                                    formFields.fromFieldType === 'NEW_FIELD' &&
                                    formFields.whoPopulatesField === 'BUYER'
                                ).length -
                                  1 &&
                              index2 % 2 === 0
                              ? 'w-full p-0'
                              : '',
                            fieldType === 'TEXT_AREA' ? 'w-full p-0' : ''
                          )}>
                          {(fieldType === 'TEXT' ||
                            fieldType === 'NUMBER' ||
                            fieldType === 'CURRENCY' ||
                            fieldType === 'TEXT_AREA') && (
                            <AppInputField
                              label={fieldName}
                              inputType={
                                fieldType === 'TEXT_AREA'
                                  ? 'textarea'
                                  : fieldType === 'NUMBER' || fieldType === 'CURRENCY'
                                  ? 'number'
                                  : 'text'
                              }
                              value={releaseGroupDetails[index].formFields[id].value || ''}
                              isRequired={isFieldRequired}
                              errorText={errors?.[id] ?? ''}
                              suffix={suffix || ''}
                              prefix={prefix || ''}
                              onBlur={(e) => {
                                let val = e.target.value;
                                if (fieldType === 'CURRENCY') {
                                  if (!val) {
                                    val = null;
                                  } else {
                                    val = Number(Number(val)).toFixed(2);
                                  }
                                  handleChangeValue(id, val, index);
                                }
                              }}
                              onTextChange={(text) => {
                                let val = text;
                                if (minValue && parseInt(text) >= minValue) {
                                  return;
                                }
                                if (maxValue && parseInt(text) <= maxValue) {
                                  return;
                                }
                                if (fieldType === 'NUMBER') {
                                  if (text !== undefined && text !== null) {
                                    val = text;
                                  } else {
                                    val = null;
                                  }
                                } else if (fieldType === 'CURRENCY') {
                                  if (text !== undefined && text !== null) {
                                    val = text;
                                  } else {
                                    val = null;
                                  }
                                }
                                handleChangeValue(id, val, index);
                              }}
                            />
                          )}
                          {fieldType === 'DATE' && (
                            <AppDatePicker
                              label={fieldName}
                              selectedDate={releaseGroupDetails[index].formFields[id].value}
                              isRequired={isFieldRequired}
                              errorText={errors?.[id] ?? ''}
                              onDateSelected={(date) => {
                                handleChangeValue(id, date, index);
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                </div>
                {currentRelease.currentPdfUrl !== '' && (
                  <div className="flex flex-col h-full GREY_100-BG py-2">
                    <div className="h-full relative  overflow-scroll flex flex-col items-center">
                      <div
                        style={{
                          width: '560px',
                          height: '54vh',
                        }}
                        className="relative ">
                        {currentRelease.currentPdfUrl && (
                          <Viewer
                            defaultScale={SpecialZoomLevel.PageFit}
                            fileUrl={currentRelease.currentPdfUrl}
                            plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                          />
                        )}
                      </div>
                      <div className="flex flex-row justify-center text-xs">
                        <ZoomOutButton />
                        <ZoomPopover />
                        <ZoomInButton />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
