import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';

export const getP21Invoices = createAsyncThunk(
  'p21/getP21Invoices',
  async (
    {
      startAfter,
      itemsPerPage,
    }: {
      startAfter?: string | null;
      itemsPerPage: number;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<any>(`${BASE_API_URL}/suppliers/p21/getP21Invoices`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        params: {
          startAfter,
          itemsPerPage,
        },
      });
      console.log({ response });

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
