import { useEffect, useMemo, useState } from 'react';
import { capitalizeWordsInSentence, SupplierJobUpdate } from '@dill/dill-shared';
import { X } from 'phosphor-react';

import { COLORS } from '../../../utils/colors';

import { AppButton } from '../AppButton/AppButton';
import { AppInputField } from '../AppInputField/AppInputField';
import { AppDropDown } from '../AppDropDown/AppDropDown';
import { useAppSelector } from '../../../redux/store';

import './AppNewSupplierJobModal.scss';
import { VerifyJobRequestData } from '../../../redux/services/verifyAddressService';

interface AddSupplierJobFormProps {
  className?: string;
  onClose: () => void;
  onFormSubmit: (jobAddressData: VerifyJobRequestData) => void;
  editMode: 'ADD' | 'EDIT';
  jobSiteData: PartialSupplierJobPreVerify;
}

type PartialSupplierJobPreVerify = Pick<
  SupplierJobUpdate,
  | 'buyerId'
  | 'name'
  | 'jobNumber'
  | 'jobAddress'
  | 'jobCity'
  | 'jobState'
  | 'jobZipCode'
  | 'constructionMonitorPermitId'
>;

const INITIAL_STATE = {
  name: '',
  jobNumber: '',
  jobAddress: '',
  jobCity: '',
  jobState: '',
  jobZipCode: '',
  buyerId: '',
  constructionMonitorPermitId: null,
} as PartialSupplierJobPreVerify;

const AddSupplierJobForm = (props: AddSupplierJobFormProps) => {
  const { className, onClose, editMode, onFormSubmit, jobSiteData } = props;

  const [loading, setLoading] = useState(false);
  const [showErrorAbove, setShowErrorAbove] = useState(false);

  const [supplierJobInput, setSupplierJobInput] = useState(INITIAL_STATE);

  const [errors, setErrors] = useState(INITIAL_STATE);

  const onTextChange = (key: string, value: string) => {
    setSupplierJobInput((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const { supplierBuyers } = useAppSelector((state) => state.supplierBuyers);
  const { user } = useAppSelector((state) => state.auth);

  const fetchedSupplierBuyers = useMemo(() => {
    if (supplierBuyers.length > 0) {
      return supplierBuyers;
    }
    return [];
  }, [supplierBuyers]);

  useEffect(() => {
    if (jobSiteData) {
      setSupplierJobInput({
        name: jobSiteData.name,
        jobNumber: jobSiteData.jobNumber,
        jobAddress: jobSiteData.jobAddress,
        jobCity: jobSiteData.jobCity,
        jobState: jobSiteData.jobState,
        jobZipCode: jobSiteData.jobZipCode,
        buyerId: jobSiteData.buyerId,
        constructionMonitorPermitId: jobSiteData.constructionMonitorPermitId,
      });
    }
  }, [jobSiteData, supplierBuyers]);

  const submitFom = async () => {
    setLoading(true);
    setShowErrorAbove(false);

    const newErrors = {} as PartialSupplierJobPreVerify;

    if (!supplierJobInput.name) {
      newErrors.name = 'Please enter a job name';
    }
    if (!supplierJobInput.jobAddress) {
      newErrors.jobAddress = 'Please enter a job address';
    }
    if (!supplierJobInput.jobCity) {
      newErrors.jobCity = 'Please enter a job city';
    }
    if (!supplierJobInput.jobState) {
      newErrors.jobState = 'Please enter a job state';
    }
    if (!supplierJobInput.jobZipCode) {
      newErrors.jobZipCode = 'Please enter a job zip code';
    }
    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      setShowErrorAbove(true);
      setLoading(false);
      return;
    }

    const jobAddressData: VerifyJobRequestData = {
      address: supplierJobInput.jobAddress ?? '',
      city: supplierJobInput.jobCity ?? '',
      state: supplierJobInput.jobState ?? '',
      zipCode: supplierJobInput.jobZipCode ?? '',
      buyerId: supplierJobInput.buyerId ?? '',
      jobNumber: supplierJobInput.jobNumber ?? '',
      name: supplierJobInput.name ?? '',
    };

    await onFormSubmit(jobAddressData);
    setLoading(false);
  };

  return (
    <>
      <div className={`new-job-modal-content pt-3 ${className}`}>
        <div className="new-job-modal-title-container">
          <h3 className="font-medium text-lg">{editMode == 'EDIT' ? 'Edit Job' : 'New Job'}</h3>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={onClose}
          />
        </div>
        <div className="content-job !overflow-y-auto">
          <div className="flex flex-row px-6 py-3">
            <div className="flex flex-col gap-2 w-full">
              <AppDropDown
                isRequired
                label="Contractor"
                errorText={errors.buyerId ? errors.buyerId : ''}
                value={supplierJobInput.buyerId || ''}
                items={[
                  ...fetchedSupplierBuyers.map((buyer) => {
                    return {
                      label: capitalizeWordsInSentence(buyer.name),
                      value: buyer.id,
                    };
                  }),
                ]}
                onSelectChange={(item) => {
                  if (item && item?.value !== null) {
                    const foundSupplierBuyer = supplierBuyers.find(
                      (supBuy) => supBuy.id === item?.value
                    );
                    if (foundSupplierBuyer) {
                      onTextChange('buyerId', item.value);
                    }
                  }
                }}
              />
              <div className="flex flex-row justify-around gap-2">
                <div className="w-full">
                  <AppInputField
                    id="name"
                    label="Job Name"
                    isRequired={true}
                    value={supplierJobInput.name}
                    errorText={errors.name ? errors.name : ''}
                    onTextChange={(text: string) => {
                      onTextChange('name', text);
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="number"
                    label="Your Job Number"
                    placeholder="12345"
                    value={supplierJobInput.jobNumber}
                    onTextChange={(text) => {
                      onTextChange('jobNumber', text);
                    }}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="w-full">
                  <AppInputField
                    id="address"
                    label="Job Address"
                    isRequired={true}
                    value={supplierJobInput.jobAddress}
                    errorText={errors.jobAddress ? errors.jobAddress : ''}
                    onTextChange={(text) => {
                      onTextChange('jobAddress', text);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-around gap-2">
                <div className="w-full">
                  <AppInputField
                    id="city"
                    label="Job City"
                    isRequired={true}
                    value={supplierJobInput.jobCity}
                    errorText={errors.jobCity ? errors.jobCity : ''}
                    onTextChange={(text) => {
                      onTextChange('jobCity', text);
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="state"
                    label="Job State"
                    isRequired={true}
                    value={supplierJobInput.jobState}
                    errorText={errors.jobState ? errors.jobState : ''}
                    onTextChange={(text) => {
                      onTextChange('jobState', text);
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="zip"
                    label="Zip Code"
                    isRequired={true}
                    value={supplierJobInput.jobZipCode}
                    errorText={errors.jobZipCode ? errors.jobZipCode : ''}
                    onTextChange={(text) => {
                      onTextChange('jobZipCode', text);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full mb-3">
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
          {showErrorAbove && (
            <div className="my-1 text-xs ERROR_500-CLR text-center">Please see errors above</div>
          )}
          <div className="flex flex-row self-end px-6">
            <AppButton
              text="Close"
              type="TERTIARY"
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                marginRight: '12px',
                height: '40px',
              }}
              onClick={onClose}
            />
            <AppButton
              text={
                editMode == 'EDIT'
                  ? `${user?.userSupplier?.ownerVerificationEnabled ? 'Edit & Verify' : 'Next'}`
                  : `${user?.userSupplier?.ownerVerificationEnabled ? 'Add & Verify' : 'Next'}`
              }
              onClick={submitFom}
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                width: '100px',
                height: '40px',
              }}
              isLoading={loading}
              isDisabled={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSupplierJobForm;
