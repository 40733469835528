import { createSlice } from '@reduxjs/toolkit';
import { SupplierReleaseApiResponse } from '@dill/dill-shared';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  createSupplierReleaseExtraReducer,
  deleteSupplierReleaseExtraReducer,
  getSupplierReleaseExtraReducer,
  getSupplierReleasesExtraReducer,
  updateSupplierReleaseExtraReducer,
  getSupplierJobReleasesExtraReducer,
} from '../reducers/supplierReleasesReducers';

export interface SupplierReleasesInitialState {
  loadingList: string[];
  supplierReleases: SupplierReleaseApiResponse[];
  selectedSupplierRelease: SupplierReleaseApiResponse | null;
  loadingErrorList: { [x: string]: string[] }[];
  selectedSupplierReleases: SupplierReleaseApiResponse[];
}

const initialState: SupplierReleasesInitialState = {
  loadingList: [],
  supplierReleases: [],
  selectedSupplierRelease: null,
  loadingErrorList: [],
  selectedSupplierReleases: [],
};

export const supplierReleasesSlice = createSlice({
  name: 'supplierReleases',
  initialState,
  reducers: {
    resetSupplierReleaseRootState: () => initialState,
    selectSupplierReleases: (state, action: PayloadAction<any[]>) => {
      const ii = [...state.selectedSupplierReleases.map((iu) => ({ ...iu }))];
      const filteredSupplierLiens = ii.filter(
        (liens) => !action.payload.map((yy: any) => yy.id).includes(liens.id)
      );
      state.selectedSupplierReleases = [...filteredSupplierLiens, ...action.payload];
    },
    selectSupplierRelease: (state, action: PayloadAction<SupplierReleaseApiResponse | null>) => {
      state.selectedSupplierRelease = action.payload;
    },
    unSelectSupplierReleases: (state, action: PayloadAction<any[]>) => {
      const filteredSupplierLiens = state.selectedSupplierReleases.filter(
        (supplierReleases) => !action.payload.map((yy: any) => yy.id).includes(supplierReleases.id)
      );
      state.selectedSupplierReleases = [...filteredSupplierLiens];
    },
    resetSupplierRelease: (state) => {
      state.selectedSupplierReleases = [];
      state.selectedSupplierRelease = null;
    },
  },
  extraReducers(builder) {
    createSupplierReleaseExtraReducer(builder);
    getSupplierReleasesExtraReducer(builder);
    getSupplierReleaseExtraReducer(builder);
    updateSupplierReleaseExtraReducer(builder);
    deleteSupplierReleaseExtraReducer(builder);
    getSupplierJobReleasesExtraReducer(builder);
  },
});

export const {
  resetSupplierReleaseRootState,
  selectSupplierReleases,
  selectSupplierRelease,
  unSelectSupplierReleases,
  resetSupplierRelease,
} = supplierReleasesSlice.actions;
