import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth, myFirebaseStorage } from '../../firebase/config';
import { getUserSalesReceipts } from './suppliersService';
import { ref, uploadBytes } from 'firebase/storage';
import { JobUpdate } from '@dill/dill-shared';

export const createJob = createAsyncThunk(
  'jobs/createJob',
  async (
    {
      mainBuyerId,
      details: {
        name,
        number,
        customerNumber,
        // supplierNumber,
        address,
        city,
        state,
        zipCode,
        owner,
        accountantEmails,
        defaultThroughDate,
        endDate,
        startDate,
        subSupplierIds,
        releaseRequirements,
        templates,
        billingRep,
        notes,
        GC,
      },
    }: {
      mainBuyerId: string;
      details: {
        name: string;
        number: string;
        customerNumber?: string | null;
        // supplierNumber?: string | null;
        address: string;
        city: string;
        state: string;
        zipCode: string;
        owner: string;
        accountantEmails: string[];
        defaultThroughDate: boolean;
        endDate: Date | null;
        startDate: Date | null;
        subSupplierIds: string[];
        releaseRequirements: object;
        templates: object;
        billingRep?: any | null;
        notes: string;
        GC: string;
      };
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.post<any>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/jobs/createJob`,
        {
          name,
          number,
          customerNumber,
          // supplierNumber,
          address,
          city,
          state,
          zipCode,
          owner,
          notes,
          accountantEmails,
          defaultThroughDate,
          endDate,
          startDate,
          subSupplierIds,
          releaseRequirements,
          templates,
          billingRep,
          GC,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateJob = createAsyncThunk(
  'jobs/updateJob',
  async (
    {
      jobDetails,
      jobId,
      mainBuyerId,
    }: {
      jobDetails: JobUpdate;
      jobId: string;
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<any>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/jobs/updateJob/${jobId}`,
        { ...jobDetails },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateBillingRepJobs = createAsyncThunk(
  'jobs/updateBillingRepJobs',
  async (
    {
      userId,
      subUserId,
      mainBuyerId,
    }: {
      userId: string;
      subUserId?: string | null;
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<any>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/jobs/updateBillingRepJobs`,
        { userId, subUserId, mainBuyerId },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getJobs = createAsyncThunk(
  'jobs/getJobs',
  async ({ mainBuyerId }: { mainBuyerId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + `/mainBuyers/${mainBuyerId}/jobs/getJobs`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getJob = createAsyncThunk(
  'jobs/getJob',
  async (
    { jobId, timezone, mainBuyerId }: { jobId: string; timezone?: string; mainBuyerId: string },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + `/mainBuyers/${mainBuyerId}/jobs/getJob/${jobId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          params: {
            timezone,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
