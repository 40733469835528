import { useState, useEffect } from 'react';
import { Modal } from '@mui/material';

import { SupplierJobApiResponse, SupplierJobUpdate } from '@dill/dill-shared';

import { openDillToast } from '../../../utils/helpers';

import { getSupplierBuyers } from '../../../redux/services/supplierBuyersService';
import { createSupplierJob, updateSupplierJob } from '../../../redux/services/supplierJobsService';

import './AppNewSupplierJobModal.scss';

import { useAppDispatch, useAppSelector } from '../../../redux/store';
import AddSupplierJobForm from './AddSupplierJobForm';
import {
  ContructionMonitorPermitOption,
  fetchQueuryJobSiteAddresses,
  VerifyJobRequestData,
} from '../../../redux/services/verifyAddressService';

import stringSimilarity from 'string-similarity';
import SupplierVerifyJobForm from './SupplierVerifyJobForm';
import { SupplierVerifiedJobFormData } from './constants';

type PartialSupplierJobUpdatePick = Pick<
  SupplierJobUpdate,
  | 'name'
  | 'jobNumber'
  | 'jobAddress'
  | 'jobCity'
  | 'jobState'
  | 'jobZipCode'
  | 'owner'
  | 'ownerAddress'
  | 'ownerCity'
  | 'ownerState'
  | 'ownerZipCode'
  | 'GC'
  | 'GCAddress'
  | 'GCCity'
  | 'GCState'
  | 'GCZipCode'
  | 'buyerId'
  | 'lender'
  | 'lenderAddress'
  | 'lenderCity'
  | 'lenderState'
  | 'lenderZipCode'
  | 'constructionMonitorPermitId'
  | 'apn'
>;

enum ModalState {
  ADD_JOB,
  EDIT_JOB,
  VERIFY_JOB,
}

export const AppNewSupplierJobModal = ({
  open,
  handleClose,
  editMode = false,
  selectedSupplierJob,
  viewState = ModalState.ADD_JOB,
  onReload,
}: {
  open: boolean;
  handleClose: () => void;
  editMode?: boolean;
  selectedSupplierJob?: SupplierJobApiResponse | null;
  viewState?: ModalState;
  onReload?: () => void;
}) => {
  const INITIAL_STATE = {
    name: '',
    jobNumber: '',
    jobAddress: '',
    jobCity: '',
    jobState: '',
    jobZipCode: '',
    owner: '',
    ownerAddress: '',
    ownerCity: '',
    ownerState: '',
    ownerZipCode: '',
    GC: '',
    GCAddress: '',
    GCCity: '',
    GCState: '',
    GCZipCode: '',
    buyerId: '',
    lender: '',
    lenderAddress: '',
    lenderCity: '',
    lenderState: '',
    lenderZipCode: '',
    constructionMonitorPermitId: null,
    apn: '',
  } as PartialSupplierJobUpdatePick;

  const [verifyJobData, setVerifyJobData] = useState<SupplierVerifiedJobFormData>(
    {} as SupplierVerifiedJobFormData
  );

  const dispatch = useAppDispatch();

  const [supplierJobInput, setSupplierJobInput] = useState(INITIAL_STATE);

  const { user } = useAppSelector((state) => state.auth);

  const [modalState, setModalState] = useState<ModalState>(viewState);

  useEffect(() => {
    if (open) {
      dispatch(getSupplierBuyers());
    }
  }, [open]);

  useEffect(() => {
    if (selectedSupplierJob && open) {
      setSupplierJobInput({
        name: selectedSupplierJob.name ?? '',
        jobNumber: selectedSupplierJob.jobNumber ? String(selectedSupplierJob.jobNumber) : '',
        jobAddress: selectedSupplierJob.jobAddress ?? '',
        jobCity: selectedSupplierJob.jobCity ?? '',
        jobState: selectedSupplierJob.jobState ?? '',
        jobZipCode: selectedSupplierJob.jobZipCode ? String(selectedSupplierJob.jobZipCode) : '',
        owner: selectedSupplierJob.owner ?? '',
        ownerAddress: selectedSupplierJob.ownerAddress ?? '',
        ownerCity: selectedSupplierJob.ownerCity ?? '',
        ownerState: selectedSupplierJob.ownerState ?? '',
        ownerZipCode: selectedSupplierJob.ownerZipCode
          ? String(selectedSupplierJob.ownerZipCode)
          : '',
        GC: selectedSupplierJob.GC ?? '',
        GCAddress: selectedSupplierJob.GCAddress ?? '',
        GCCity: selectedSupplierJob.GCCity ?? '',
        GCState: selectedSupplierJob.GCState ?? '',
        GCZipCode: selectedSupplierJob.GCZipCode ? String(selectedSupplierJob.GCZipCode) : '',
        buyerId: selectedSupplierJob.buyerId ?? '',
        lender: selectedSupplierJob.lender ?? '',
        lenderAddress: selectedSupplierJob.lenderAddress ?? '',
        lenderCity: selectedSupplierJob.lenderCity ?? '',
        lenderState: selectedSupplierJob.lenderState ?? '',
        lenderZipCode: selectedSupplierJob.lenderZipCode
          ? String(selectedSupplierJob.lenderZipCode)
          : '',
        constructionMonitorPermitId: selectedSupplierJob.constructionMonitorPermitId,
        apn: selectedSupplierJob.apn ?? '',
      });
    }
  }, [open, selectedSupplierJob]);

  const handleCloseModal = () => {
    handleClose();
    setSupplierJobInput(INITIAL_STATE);
    setModalState(ModalState.ADD_JOB);
    setSupplierJobInput({} as SupplierVerifiedJobFormData);
  };

  const handleSubmitJob = async (formData: SupplierVerifiedJobFormData) => {
    const data = {
      supplierId: user?.userSupplier?.id || '',
      name: formData.name || '',
      jobNumber: formData.jobNumber || '',
      jobAddress: formData.jobAddress || '',
      jobCity: formData.jobCity || '',
      jobState: formData.jobState || '',
      jobZipCode: formData.jobZipCode,
      owner: formData.owner || '',
      ownerAddress: formData.ownerAddress || '',
      ownerCity: formData.ownerCity || '',
      ownerState: formData.ownerState || '',
      ownerZipCode: formData.ownerZipCode || '',
      buyerId: formData.buyerId || '',
      GC: formData.GC || '',
      GCAddress: formData.GCAddress || '',
      GCCity: formData.GCCity || '',
      GCState: formData.GCState || '',
      GCZipCode: formData.GCZipCode || '',
      lender: formData.lender || '',
      lenderAddress: formData.lenderAddress || '',
      lenderCity: formData.lenderCity || '',
      lenderState: formData.lenderState || '',
      lenderZipCode: formData.lenderZipCode || '',
      apn: formData.apn || '',
      constructionMonitorPermitId: Number(formData.constructionMonitorPermitId) || null,
    };

    if (editMode === false) {
      const createdSupplierJob = await dispatch(
        createSupplierJob({ supplierJobDetails: { ...data } })
      );
      if (createdSupplierJob.type === 'supplierJobs/createSupplierJob/fulfilled') {
        openDillToast({ message: 'Job successfully added!', type: 'SUCCESS' });
        handleCloseModal();
        if (onReload) {
          onReload();
        }
      } else {
        openDillToast({ message: 'Failed to add the job', type: 'ERROR' });
      }
    } else {
      const updatedSupplierJob = await dispatch(
        updateSupplierJob({
          supplierJobId: selectedSupplierJob?.id ?? '',
          supplierJobDetails: { ...data },
        })
      );
      if (updatedSupplierJob.type === 'supplierJobs/updateSupplierJob/fulfilled') {
        openDillToast({ message: 'Job successfully updated!', type: 'SUCCESS' });
        handleCloseModal();
        if (onReload) {
          onReload();
        }
      } else {
        openDillToast({ message: 'Failed to update job', type: 'ERROR' });
      }
    }
  };

  const onVerifyJobState = (dataToVerify: SupplierVerifiedJobFormData) => {
    setVerifyJobData(dataToVerify);
    setModalState(ModalState.VERIFY_JOB);
  };

  const onVerifySubmitJobAddress = async (jobAddressData: VerifyJobRequestData) => {
    if (user?.userSupplier?.ownerVerificationEnabled) {
      await dispatch(fetchQueuryJobSiteAddresses(jobAddressData)).then((res) => {
        const { address, city, state, zipCode, jobNumber, name, buyerId } = jobAddressData;
        if (res.type === 'jobOwner/fetchAddresses/fulfilled') {
          const permits = res.payload as ContructionMonitorPermitOption[];

          const queriedJobAddress = `${address}, ${city}, ${state}, ${zipCode}`;

          if (permits.length > 0) {
            // loop through results and use compareTwoStrings to queriedJobAddress to find the best match
            // best match will be the one with a score above 0.5 or null if none are above 0.5
            let bestMatch: ContructionMonitorPermitOption | null = null;
            let bestMatchScore = 0;

            for (const permit of permits) {
              const permitAddress = `${permit.ownerAddress}, ${permit.ownerCity}, ${permit.ownerState}, ${permit.ownerZipCode}`;
              const score = stringSimilarity.compareTwoStrings(queriedJobAddress, permitAddress);

              if (score > 0.5 && score > bestMatchScore) {
                bestMatch = permit;
                bestMatchScore = score;
              }
              const jobToVerify = {
                name,
                buyerId,
                owner: '',
                ownerAddress: '',
                ownerCity: '',
                ownerState: '',
                ownerZipCode: '',
                jobNumber,
                jobAddress: address,
                jobCity: city,
                jobState: state,
                jobZipCode: zipCode,
                constructionMonitorPermitId: null,
                GC: '',
                GCAddress: '',
                GCCity: '',
                GCState: '',
                GCZipCode: '',
                apn: '',
              };

              if (bestMatch) {
                onVerifyJobState({
                  ...jobToVerify,
                  owner: bestMatch.owner,
                  ownerAddress: bestMatch.ownerAddress,
                  ownerCity: bestMatch.ownerCity,
                  ownerState: bestMatch.ownerState,
                  ownerZipCode: bestMatch.ownerZipCode,
                  constructionMonitorPermitId: Number(bestMatch.constructionMonitorPermitId),
                  GC: bestMatch.gcName,
                  GCAddress: bestMatch.gcAddress,
                  GCCity: bestMatch.gcCity,
                  GCState: bestMatch.gcState,
                  GCZipCode: bestMatch.gcZipCode,
                  verifiedProperty: {
                    address: bestMatch.jobAddress,
                    city: bestMatch.jobCity,
                    state: bestMatch.jobState,
                    zipCode: bestMatch.jobZipCode,
                    apn: bestMatch.apn,
                  },
                });
              } else {
                onVerifyJobState(jobToVerify);
              }
            }
          }
        } else {
          console.error('Error fetching data: ', res);
        }
      });
    } else {
      const { address, city, state, zipCode, jobNumber, name, buyerId } = jobAddressData;
      const jobToVerify = {
        name,
        buyerId,
        owner: '',
        ownerAddress: '',
        ownerCity: '',
        ownerState: '',
        ownerZipCode: '',
        jobNumber,
        jobAddress: address,
        jobCity: city,
        jobState: state,
        jobZipCode: zipCode,
        constructionMonitorPermitId: null,
        GC: '',
        GCAddress: '',
        GCCity: '',
        GCState: '',
        GCZipCode: '',
        apn: '',
      };
      onVerifyJobState(jobToVerify);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <>
        {modalState === ModalState.ADD_JOB && (
          <AddSupplierJobForm
            jobSiteData={supplierJobInput}
            onClose={handleCloseModal}
            editMode={editMode ? 'EDIT' : 'ADD'}
            onFormSubmit={onVerifySubmitJobAddress}
          />
        )}
        {modalState === ModalState.VERIFY_JOB && (
          <SupplierVerifyJobForm
            {...verifyJobData}
            supplierJobData={supplierJobInput}
            onClose={handleCloseModal}
            onUseVerifiedData={handleSubmitJob}
            onManualDataUpdate={handleSubmitJob}
          />
        )}
      </>
    </Modal>
  );
};
