import { Modal } from '@mui/material';
import successChecksCircle from '../../../assets/svg/success_checks_circle.svg';
import { AppButton } from '../AppButton/AppButton';
import { COLORS } from '../../../utils/colors';
import { Warning } from 'phosphor-react';
import { Loader } from 'react-feather';
import errorCircle from './../../../assets/svg/error_circle.svg';

export const AppMessageModal = ({
  title = '',
  subTitle = '',
  highlightedText = '',
  buttonText = 'Back',
  open,
  handleClose,
  isSecondButton = false,
  onSecondButtonClick,
  secondButtonText = '',
  type = 'SUCCESS',
  customImage = null,
  handleCloseClickOutsideModal,
  customIcon = null,
  customIconContainerStyle = {},
}: {
  title?: string;
  subTitle?: string;
  highlightedText?: string;
  isSecondButton?: boolean;
  buttonText?: string;
  secondButtonText?: string;
  customImage?: any;
  open: boolean;
  handleClose?: () => void;
  onSecondButtonClick?: () => void;
  type: 'ERROR' | 'SUCCESS' | 'WARN' | 'LOADING' | 'CONFIRM' | 'INFO';
  handleCloseClickOutsideModal?: () => void;
  customIcon?: any;
  customIconContainerStyle?: object;
}) => {
  return (
    <Modal
      open={open}
      onClose={handleCloseClickOutsideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col px-3 py-6 min-h-8 min-h-80 w-[412px] items-center">
        {(type === 'ERROR' || type === 'SUCCESS') && (
          <div className="flex justify-center items-center rounded-full w-[110px] h-[110px] object-contain">
            {type === 'SUCCESS' && !customImage && (
              <img src={successChecksCircle} alt="" className="" />
            )}
            {type === 'ERROR' && !customImage && <img src={errorCircle} alt="" className="" />}
            {customImage && <img src={customImage} alt="" className="" />}
          </div>
        )}
        {type === 'LOADING' && !customImage && (
          <div className="loadingImage">
            <Loader size={64} color={COLORS.PRIMARY_500} />
          </div>
        )}
        {type === 'WARN' && !customImage && (
          <div className="flex h-20 w-20 WARNING_100-BG WARNING_500-CLR justify-center items-center rounded-full">
            <Warning size={45} weight="bold" />
          </div>
        )}
        {(type === 'WARN' || type === 'LOADING') && customImage && (
          <div className="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col px-3 py-6 min-h-8 min-h-80 w-[412px] items-center">
            {customImage && <img src={customImage} alt="" className="" />}
          </div>
        )}
        {customIcon && type === 'INFO' && <div style={customIconContainerStyle}>{customIcon}</div>}
        <p className="mt-4 font-bold text-center text-2xl mb-1">{title}</p>
        <p className="text-sm mb-2 TEXT_SECONDARY-CLR text-center">{subTitle}</p>
        {highlightedText && (
          <p className="PRIMARY_500-CLR text-sm font-medium">{highlightedText}</p>
        )}
        {type !== 'LOADING' && (
          <div className="flex flex-row justify-around mt-3">
            <AppButton
              text={buttonText}
              type="TERTIARY"
              buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
              onClick={handleClose}
            />
            {isSecondButton && (
              <AppButton
                text={secondButtonText}
                buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
                onClick={onSecondButtonClick}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
