import { createSlice } from '@reduxjs/toolkit';
import {
  createSupplierJobsExtraReducer,
  getSupplierJobsExtraReducer,
  updateSupplierJobExtraReducer,
  getSupplierJobExtraReducer,
  recursiveGetSupplierJobsExtraReducer,
  bulkUpdateSupplierJobExtraReducer,
} from '../reducers/supplierJobsReducers';
import { SupplierJobApiResponse } from '@dill/dill-shared';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface SupplierJobsInitialState {
  loadingList: string[];
  loadingErrorList: object[];
  selectedSupplierJob: SupplierJobApiResponse | null;
  supplierJobs: SupplierJobApiResponse[];
  selectedSupplierJobs: SupplierJobApiResponse[];
}

const initialState: SupplierJobsInitialState = {
  loadingList: [],
  loadingErrorList: [],
  selectedSupplierJob: null,
  supplierJobs: [],
  selectedSupplierJobs: [],
};

export const supplierJobsSlice = createSlice({
  name: 'supplierJobs',
  initialState,
  reducers: {
    resetSupplierJobsRootState: () => initialState,
    selectSupplierJobs: (state, action: PayloadAction<any[]>) => {
      const ii = [...state.selectedSupplierJobs.map((iu) => ({ ...iu }))];
      const filteredSupplierJobs = ii.filter(
        (supplierJob) => !action.payload.map((yy: any) => yy.id).includes(supplierJob.id)
      );
      state.selectedSupplierJobs = [...filteredSupplierJobs, ...action.payload];
    },
    unSelectSupplierJobs: (state, action: PayloadAction<any[]>) => {
      const filteredSupplierJobs = state.selectedSupplierJobs.filter(
        (supplierJob) => !action.payload.map((yy: any) => yy.id).includes(supplierJob.id)
      );
      state.selectedSupplierJobs = [...filteredSupplierJobs];
    },
    resetSupplierJobs: (state) => {
      state.selectedSupplierJobs = [];
      state.supplierJobs = [];
    },
  },
  extraReducers(builder) {
    createSupplierJobsExtraReducer(builder);
    getSupplierJobsExtraReducer(builder);
    updateSupplierJobExtraReducer(builder);
    getSupplierJobExtraReducer(builder);
    recursiveGetSupplierJobsExtraReducer(builder);
    bulkUpdateSupplierJobExtraReducer(builder);
  },
});

export const {
  resetSupplierJobsRootState,
  selectSupplierJobs,
  unSelectSupplierJobs,
  resetSupplierJobs,
} = supplierJobsSlice.actions;
