import React, { useEffect, useMemo, useState } from 'react';
import { Envelope, Phone, MapPinLine, Timer, CurrencyCircleDollar, Clock } from 'phosphor-react';
import { COLORS } from '../../../../utils/colors';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import { Buyer, DocumentsSummary, formatMoney } from '@dill/dill-shared';
import { getUserCustomer } from '../../../../redux/services/suppliersService';
import { openDillToast } from '../../../../utils/helpers';
import CustomerInvoicesTable from '../CustomerInvoicesTable/CustomerInvoicesTable';

export const CustomerDetails = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [currentBuyerId, setCurrentBuyerId] = useState<string>('');
  const [selectedBuyer, setSelectedBuyer] = useState<Buyer | null>(null);
  const [documentsSummary, setDocumentsSummary] = useState<DocumentsSummary | null>(null);

  const fetchBuyer = async (buyerId: string) => {
    const result = await dispatch(getUserCustomer({ buyerId }));
    if (result.type === 'supplier/getUserCustomer/fulfilled') {
      const payload = result.payload as any;
      if (payload?.data?.buyer) {
        const buyer: Buyer = payload.data.buyer;
        setSelectedBuyer(buyer);
      }
      if (payload?.data?.documentsSummary) {
        const buyerSummary: DocumentsSummary = payload.data.documentsSummary;
        setDocumentsSummary(buyerSummary);
      }
    } else {
      openDillToast({ message: 'Failed to fetch buyer.', type: 'ERROR' });
    }
  };

  useEffect(() => {
    const pathlist = location.pathname.split('/');
    const buyerId = pathlist[pathlist.length - 1];
    setCurrentBuyerId(buyerId);
    fetchBuyer(buyerId);
  }, [location]);

  const buyerInitial = useMemo(() => {
    if (selectedBuyer) {
      const selectedBuyerName = selectedBuyer?.name ?? '';
      const selectedBuyerNameArray = selectedBuyerName.split(' ');
      if (selectedBuyerNameArray.length > 1) {
        const letter1 = selectedBuyerNameArray[0].substring(0, 1);
        const letter2 = selectedBuyerNameArray[1].substring(0, 1);
        return `${letter1}${letter2}`.toUpperCase();
      } else {
        const letter1 = selectedBuyerNameArray[0].substring(0, 1);
        return `${letter1}`.toUpperCase();
      }
    }
    return '';
  }, [selectedBuyer]);

  const buyerAddress = useMemo(() => {
    if (selectedBuyer) {
      const selectedAddress = selectedBuyer?.address ? `${selectedBuyer?.address}, ` : '';
      const selectedCity = selectedBuyer?.city ? `${selectedBuyer?.city}, ` : '';
      const selectedState = selectedBuyer?.state ? `${selectedBuyer?.state} ` : '';
      const selectedZipCode = selectedBuyer?.zipCode ?? '';
      return `${selectedAddress}${selectedCity}${selectedState}${selectedZipCode}`;
    }
    return '';
  }, [selectedBuyer]);

  const summaryDetails = useMemo(() => {
    if (documentsSummary) {
      return [
        {
          key: 'summary_1',
          icon: (
            <CurrencyCircleDollar
              color={COLORS.SUCCESS_500}
              size={30}
              className="mr-2 p-1 rounded-full SUCCESS_100-BG"
            />
          ),
          value: formatMoney(documentsSummary?.totalAmountDueInvoices ?? '', 2),
          line1: 'AMOUNT',
          line2: 'DUE',
        },
        {
          key: 'summary_',
          icon: (
            <Clock
              color={COLORS.PRIMARY_500}
              size={24}
              className="mr-2 p-1 rounded-full PRIMARY_100-BG"
            />
          ),
          value: formatMoney(documentsSummary?.totalCurrentDueInvoices ?? '', 2),
          line1: 'CURRENT',
          line2: 'DUE',
        },
        {
          key: 'summary_3',
          icon: (
            <div className="mr-2 text-[#0456A2] bg-[#E8FFFF] p-2 rounded-full font-semibold text-xs">
              30
            </div>
          ),
          value: formatMoney(documentsSummary?.totalPastDue1To30Invoices ?? '', 2),
          line1: '1 - 30 DAYS',
          line2: 'PAST DUE',
        },
        {
          key: 'summary_4',
          icon: (
            <div className="mr-2 WARNING_100-BG WARNING_500-CLR p-2 rounded-full font-semibold text-xs">
              60
            </div>
          ),
          value: formatMoney(documentsSummary?.totalPastDue31To60Invoices ?? '', 2),
          line1: '31 - 60 DAYS',
          line2: 'PAST DUE',
        },
        {
          key: 'summary_5',
          icon: (
            <div className="mr-2 text-[#8C04A2] bg-[#FAEFFF] p-2 rounded-full font-semibold text-xs">
              90
            </div>
          ),
          value: formatMoney(documentsSummary?.totalPastDue61To90Invoices ?? '', 2),
          line1: '61 - 90 DAYS',
          line2: 'PAST DUE',
        },
        {
          key: 'summary_6',
          icon: (
            <div className="mr-2 text-[#A20404] bg-[#FFE8E8] py-2 px-1 rounded-full font-semibold text-xs">
              {`>90`}
            </div>
          ),
          value: formatMoney(documentsSummary?.totalPastDueOver90Invoices ?? '', 2),
          line1: 'OVER 90 DAYS',
          line2: 'PAST DUE',
        },
      ];
    }
    return [];
  }, [documentsSummary]);

  return (
    <div className="w-full h-full p-4">
      <div className="w-full rounded-lg WHITE-BG p-4">
        <div className="flex items-center">
          {buyerInitial && (
            <div className="flex PRIMARY_100-BG PRIMARY_500-CLR h-[70px] w-[70px] text-base rounded-full items-center justify-center font-medium mr-2">
              {buyerInitial}
            </div>
          )}
          <div>
            <div className="flex items-center mb-1">
              <div className="text-[24px] font-bold">{selectedBuyer?.name ?? ''}</div>
              {selectedBuyer?.quickbooksCustomerEmails &&
                selectedBuyer?.quickbooksCustomerEmails.length > 0 && (
                  <div className="flex PRIMARY_100-BG PRIMARY_500-CLR items-center px-3 rounded-full h-[24px] ml-2 text-xs">
                    <Envelope color={COLORS.PRIMARY_500} size={12} className="mr-1" />
                    <div>{selectedBuyer?.quickbooksCustomerEmails?.join(', ') ?? ''}</div>
                  </div>
                )}
            </div>

            <div className="flex">
              {selectedBuyer?.phoneNumbers && selectedBuyer?.phoneNumbers.length > 0 && (
                <div className="flex items-center GREY_500-CLR font-semibold text-sm">
                  <Phone size={20} className="mr-1" />
                  <div>{selectedBuyer?.phoneNumbers?.join(', ') ?? ''}</div>
                </div>
              )}
              {buyerAddress && (
                <div className="flex items-center GREY_500-CLR font-semibold text-sm">
                  <div className="PRIMARY_200-BG mr-2 rounded-full ml-2 h-[5px] w-[5px]" />
                  <MapPinLine size={20} className="mr-1" />
                  <div>{buyerAddress}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-4 flex w-full overflow-x-auto">
          {summaryDetails &&
            summaryDetails.length > 0 &&
            summaryDetails.map((summary) => {
              return (
                <div
                  key={summary.key}
                  className="GREY_200-BORDER border min-w-[180px] p-4 rounded-lg mr-4">
                  <div className="flex items-center mb-2">
                    {summary.icon}
                    <div className="font-bold text-[18px] ">{summary.value}</div>
                  </div>
                  <div className="text-sm GREY_500-CLR font-medium tracking-wider">
                    {summary.line1}
                  </div>
                  <div className="text-sm GREY_500-CLR font-medium tracking-wider">
                    {summary.line2}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="w-full rounded-lg WHITE-BG mt-4 h-[calc(95vh-330px)] overflow-y-hidden">
        <CustomerInvoicesTable selectedBuyer={selectedBuyer} />
      </div>
    </div>
  );
};
