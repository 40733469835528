import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { ManualUploadInvoiceUpdate } from '@dill/dill-shared';

export const manualUploadData = createAsyncThunk(
  'manualUpload/manualUploadData',
  async (
    {
      folderPath,
    }: {
      folderPath: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.post<any>(
        `${BASE_API_URL}/suppliers/manualUpload/manualUploadData`,
        {
          folderPath,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          timeout: 1000 * 60 * 60,
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      let errorMessage = '';
      if (error?.response?.data?.errors) {
        errorMessage = [...error?.response?.data?.errors].reduce((prev, curr) => {
          if (curr.msg) {
            return `${prev} ${curr.msg}`;
          }
          return prev;
        }, '');
      } else {
        errorMessage = error?.message;
      }
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const getManualUploadInvoices = createAsyncThunk(
  'manualUpload/getManualUploadInvoices',
  async (
    {
      startAfter,
      itemsPerPage,
    }: {
      startAfter?: string | null;
      itemsPerPage: number;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<any>(
        `${BASE_API_URL}/suppliers/manualUpload/getManualUploadInvoices`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          params: {
            startAfter,
            itemsPerPage,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getManualUploadInvoicesIds = createAsyncThunk(
  'manualUpload/getManualUploadInvoicesIds',
  async (
    {
      invoiceIds,
    }: {
      invoiceIds: string[];
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<any>(
        `${BASE_API_URL}/suppliers/manualUpload/getManualUploadInvoicesIds`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          params: {
            invoiceIds,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const recursiveGetManualUploadInvoices = createAsyncThunk(
  'manualUpload/recursiveGetManualUploadInvoices',
  async (
    {
      startAfter,
      itemsPerPage,
    }: {
      startAfter?: string | null;
      itemsPerPage?: number;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<any>(
        `${BASE_API_URL}/suppliers/manualUpload/getManualUploadInvoices`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          params: {
            startAfter,
            itemsPerPage,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getManualUploadJobs = createAsyncThunk(
  'manualUpload/getManualUploadJobs',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<any>(
        `${BASE_API_URL}/suppliers/manualUpload/getManualUploadJobs`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getManualUploadCustomers = createAsyncThunk(
  'manualUpload/getManualUploadCustomers',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<any>(
        `${BASE_API_URL}/suppliers/manualUpload/getManualUploadCustomers`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
