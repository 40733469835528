import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import { getSage100Invoices } from '../services/sage100Service';
import { Sage100InitialState } from '../globalSlices/sage100Slice';

export const getSage100InvoicesExtraReducer = (
  builder: ActionReducerMapBuilder<Sage100InitialState>
) => {
  const reducerId = 'getSage100Invoices';
  builder.addCase(getSage100Invoices.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getSage100Invoices.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      state.sage100Invoices = action.payload?.data;
    }
  );
  builder.addCase(getSage100Invoices.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
