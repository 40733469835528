import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoginPage, ReferPage } from './pages';
import { DashboardPage } from './pages/DashboardPage/DashboardPage';
import { ProtectedRoute } from './ProtectedRoute';
import { Suppliers } from './components/modules/suppliers/Suppliers/Suppliers';
import { ViewSupplierInvoices } from './components/modules/suppliers/ViewSupplierInvoices/ViewSupplierInvoices';
import { Customers } from './components/modules/customers/Customers/Customers';
import { CustomerDetails } from './components/modules/customers/CustomerDetails/CustomerDetails';
// import SupplierInvoices from './components/modules/suppliers/SupplierInvoices/SupplierInvoices';
import ManualUploadInvoices from './components/modules/suppliers/ManualUploadInvoices/ManualUploadInvoices';
import { BuyerPayments } from './components/modules/payments/BuyerPayments/BuyerPayments';
import { SupplierPayments } from './components/modules/payments/SupplierPayments/SupplierPayments';
import { HelpPage } from './components/modules/help/HelpPage/HelpPage';
import SupplierSalesReceipts from './components/modules/suppliers/SupplierSalesReceipts/SupplierSalesReceipts';
import LienWaivers from './components/modules/lienWaivers/supplier/LienWaivers/LienWaivers';
import LienWaiverDocumentView from './components/modules/lienWaivers/supplier/LienWaiverDocumentView/LienWaiverDocumentView';
import BuyerLienJobs from './components/modules/lienWaivers/buyerLiens/BuyerLienJobs/BuyerLienJobs';
import BuyerLienSuppliers from './components/modules/lienWaivers/buyerLiens/BuyerLienSuppliers/BuyerLienSuppliers';
import BuyerLienTemplates from './components/modules/lienWaivers/buyerLiens/BuyerLienTemplates/BuyerLienTemplates';
import BuyerLienReleases from './components/modules/lienWaivers/buyerLiens/BuyerLienReleases/BuyerLienReleases';
import BuyerAddLienRelease from './components/modules/lienWaivers/buyerLiens/BuyerAddLienRelease/BuyerAddLienRelease';
import BuyerEditLienRelease from './components/modules/lienWaivers/buyerLiens/BuyerEditLienRelease/BuyerEditLienRelease';
import BuyerTemplatesFields from './components/modules/lienWaivers/buyerLiens/BuyerTemplatesFields/BuyerTemplatesFields';
import BuyerAddLienTemplate from './components/modules/lienWaivers/buyerLiens/BuyerAddLienTemplate/BuyerAddLienTemplate';
import BuyerReleaseJobDetails from './components/modules/lienWaivers/buyerLiens/BuyerReleaseJobDetails/BuyerReleaseJobDetails';
import NoticeTemplates from './components/modules/suppliers/NoticeTemplates/NoticeTemplates';
import AddNoticeTemplate from './components/modules/suppliers/AddNoticeTemplate/AddNoticeTemplate';
import NoticeTemplatesFields from './components/modules/suppliers/NoticeTemplatesFields/NoticeTemplatesFields';
import PreliminaryNotices from './components/modules/suppliers/PreliminaryNotices/PreliminaryNotices';
import AddPreliminaryNotice from './components/modules/suppliers/AddPreliminaryNotice/AddPreliminaryNotice';
import RootSetupPage from './pages/RootSetupPage/RootSetupPage';
import { ReferSetupPage } from './pages/ReferSetupPage/ReferSetupPage';
import BuyerManualAddLienRelease from './components/modules/lienWaivers/buyerLiens/BuyerManualAddLienRelease/BuyerManualAddLienRelease';
import SupplierJobs from './components/modules/suppliers/SupplierJobs/SupplierJobs';
import SupplierJobDetails from './components/modules/suppliers/SupplierJobDetails/SupplierJobDetails';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';

function AppRouter() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootSetupPage redirectToPath="/login" />,
      // element: <Navigate to="/login" />,
    },
    // {
    //   path: '/?*',
    //   element: <RootSetupPage redirectToPath="/login" />,
    // },
    {
      path: '/supplierJobs/*',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <SupplierJobs />
            </ProtectedRoute>
          ),
        },
        {
          path: ':supplierJoId',
          element: (
            <ProtectedRoute>
              <SupplierJobDetails />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/invoices/*',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <ManualUploadInvoices />
            </ProtectedRoute>
          ),
        },
      ],
      // children: [
      //   {
      //     path: '',
      //     element: (
      //       <ProtectedRoute>
      //         <SupplierInvoices />
      //       </ProtectedRoute>
      //     ),
      //   },
      // ],
    },
    {
      path: '/salesReceipts/*',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <SupplierSalesReceipts />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/customers/*',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Customers />
            </ProtectedRoute>
          ),
        },
        {
          path: ':customerId',
          element: (
            <ProtectedRoute>
              <CustomerDetails />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/help/*',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <HelpPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/payments/*',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <SupplierPayments />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/preliminaryNotices/*',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'overview',
          element: (
            <ProtectedRoute>
              <PreliminaryNotices />
            </ProtectedRoute>
          ),
        },
        {
          path: 'overview/addPreliminaryNotice',
          element: (
            <ProtectedRoute>
              <AddPreliminaryNotice />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/lienReleases/*',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <LienWaivers />
            </ProtectedRoute>
          ),
        },
        {
          path: ':lienReleaseId',
          element: (
            <ProtectedRoute>
              <LienWaiverDocumentView />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/noticeTemplates/*',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'templates',
          element: (
            <ProtectedRoute>
              <NoticeTemplates />
            </ProtectedRoute>
          ),
        },
        {
          path: 'templates/addTemplate',
          element: (
            <ProtectedRoute>
              <AddNoticeTemplate />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/noticeTemplateFields',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <NoticeTemplatesFields />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/companies',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Suppliers />
            </ProtectedRoute>
          ),
        },
      ],
    },

    {
      path: '/companies/:mainBuyerId',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Suppliers />
            </ProtectedRoute>
          ),
        },
        {
          path: 'invoices',
          element: (
            <ProtectedRoute>
              <Suppliers />
            </ProtectedRoute>
          ),
        },
        {
          path: 'invoices',
          element: (
            <ProtectedRoute>
              <Suppliers />
            </ProtectedRoute>
          ),
        },
        {
          path: 'invoices/:supplierDomainId',
          element: (
            <ProtectedRoute>
              <ViewSupplierInvoices />
            </ProtectedRoute>
          ),
        },
        {
          path: 'payments',
          element: (
            <ProtectedRoute>
              <BuyerPayments />
            </ProtectedRoute>
          ),
        },
        {
          path: 'payments/:restaurantGroupId',
          element: (
            <ProtectedRoute>
              <BuyerPayments />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/buyerLienReleases/:mainBuyerId',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'overview',
          element: (
            <ProtectedRoute>
              <BuyerLienReleases />
            </ProtectedRoute>
          ),
        },
        {
          path: 'overview/addLienRelease',
          element: (
            <ProtectedRoute>
              <BuyerAddLienRelease />
            </ProtectedRoute>
          ),
        },
        {
          path: 'overview/manualAddLienRelease',
          element: (
            <ProtectedRoute>
              <BuyerManualAddLienRelease />
            </ProtectedRoute>
          ),
        },
        {
          path: 'overview/editRelease/:releaseId',
          element: (
            <ProtectedRoute>
              <BuyerEditLienRelease />
            </ProtectedRoute>
          ),
        },
        {
          path: 'lienReleasesJobs',
          element: (
            <ProtectedRoute>
              <BuyerLienJobs />
            </ProtectedRoute>
          ),
        },
        {
          path: 'lienReleasesSupplier',
          element: (
            <ProtectedRoute>
              <BuyerLienSuppliers />
            </ProtectedRoute>
          ),
        },
        {
          path: 'templates',
          element: (
            <ProtectedRoute>
              <BuyerLienTemplates />
            </ProtectedRoute>
          ),
        },
        {
          path: 'templates/addTemplate',
          element: (
            <ProtectedRoute>
              <BuyerAddLienTemplate />
            </ProtectedRoute>
          ),
        },
        {
          path: 'templateFields',
          element: (
            <ProtectedRoute>
              <BuyerTemplatesFields />
            </ProtectedRoute>
          ),
        },
        {
          path: 'lienReleasesJobs/:jobId',
          element: (
            <ProtectedRoute>
              <BuyerReleaseJobDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: 'lienReleasesJobs/:jobId/addRelease',
          element: (
            <ProtectedRoute>
              <BuyerAddLienRelease />
            </ProtectedRoute>
          ),
        },
        {
          path: 'lienReleasesJobs/:jobId/editRelease/:releaseId',
          element: (
            <ProtectedRoute>
              <BuyerEditLienRelease />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/suppliers/*',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
    },
    {
      path: '/priceList',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
    },
    {
      path: '/contacts',
      element: (
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      ),
    },
    {
      path: '/login',
      element: <LoginPage />,
      children: [
        {
          path: '',
          element: <LoginPage />,
        },
        {
          path: '*',
          element: <LoginPage />,
        },
      ],
    },
    {
      path: '/portal',
      element: <LoginPage />,
      children: [
        {
          path: '',
          element: <LoginPage />,
        },
        {
          path: '*',
          element: <LoginPage />,
        },
      ],
    },
    {
      path: '/refer',
      element: <ReferSetupPage />,
    },
    {
      path: '/refer/add',
      element: <ReferPage />,
    },
    {
      path: '/*',
      element: (
        <ProtectedRoute>
          <NotFoundPage />
        </ProtectedRoute>
      ),
    },
  ]);

  return (
    <div className="w-full h-full">
      <RouterProvider router={router} />
    </div>
  );
}

export default AppRouter;
