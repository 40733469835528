import React, { useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../utils/colors';
import { twMerge } from 'tailwind-merge';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import { QuickbooksInvoice, formatMoney } from '@dill/dill-shared/dist/src';
import emptyListImg from '../../../assets/images/emptyList.png';
import moment from 'moment';
import { InvoiceReference } from '../../../constants/invoice';

export const AppInvoiceTable = ({
  invoices,
  onSelectInvoice,
  invoicesSelected,
  paidInvoicesWeeksAgo,
  tableStyle = {},
  usedInvoices,
}: {
  invoices: {
    paidInvoices: QuickbooksInvoice[];
    unPaidInvoices: QuickbooksInvoice[];
  };
  onSelectInvoice: (invoices: InvoiceReference[]) => void;
  invoicesSelected?: QuickbooksInvoice[];
  paidInvoicesWeeksAgo?: number;
  tableStyle?: React.CSSProperties;
  usedInvoices?: { invoiceNumber: string; type: string; date: string }[];
}) => {
  const [selectedInvoicesOption, setSelectedInvoicesOption] = useState<'PAID' | 'UNPAID'>('PAID');
  const [selectedInvoices, setSelectedInvoices] = useState<QuickbooksInvoice[]>([]);

  const columns = useMemo(() => {
    if (usedInvoices) {
      return [
        { id: 'select', name: '', width: 50, cellType: 'VIEW_TEXT' },
        { id: 'invoiceDate', name: 'Invoice Date', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'invoiceNumber', name: 'Invoice #', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
        { id: 'alreadyOn', name: 'Already On', width: 180, cellType: 'VIEW_TEXT' },
      ];
    }
    return [
      { id: 'select', name: '', width: 50, cellType: 'VIEW_TEXT' },
      { id: 'invoiceDate', name: 'Invoice Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'invoiceNumber', name: 'Invoice #', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
    ];
  }, [usedInvoices]);
  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );
  const filteredInvoices = useMemo(() => {
    if (!invoicesSelected) {
      setSelectedInvoices([]);
    }
    if (selectedInvoicesOption === 'UNPAID') {
      return invoices.unPaidInvoices;
    }
    return invoices.paidInvoices;
  }, [selectedInvoicesOption, invoices]);

  const totalAmount = useMemo(() => {
    let accumulatedAmount = 0;
    if (selectedInvoices.length > 0) {
      selectedInvoices.forEach((invoice) => {
        if (invoice.amount) {
          accumulatedAmount += invoice.amount;
        }
      });
    }
    return accumulatedAmount;
  }, [selectedInvoices]);

  useEffect(() => {
    if (invoicesSelected) {
      setSelectedInvoices(invoicesSelected);
    }
  }, [invoicesSelected]);

  useEffect(() => {
    const invoicesUpdate = selectedInvoices.map((selInv) => {
      return {
        invoiceNumber: selInv.docNumber,
        amount: Number(selInv.amount),
      };
    });
    onSelectInvoice(invoicesUpdate);
  }, [selectedInvoices]);

  return (
    <div className="border rounded">
      <div className="flex">
        <div
          onClick={() => setSelectedInvoicesOption('PAID')}
          className={twMerge(
            'w-1/2 p-2 ',
            selectedInvoicesOption === 'PAID'
              ? 'border-b-2 PRIMARY_500-BORDER PRIMARY_500-CLR'
              : 'hover:bg-blue-100 cursor-pointer border-r border-b GRAY_500-BORDER'
          )}>
          Paid{' '}
          {paidInvoicesWeeksAgo && (
            <span className="text-xs">(from {paidInvoicesWeeksAgo} weeks ago)</span>
          )}
        </div>
        <div
          onClick={() => setSelectedInvoicesOption('UNPAID')}
          className={twMerge(
            'w-1/2 p-2 ',
            selectedInvoicesOption === 'UNPAID'
              ? 'border-b-2 PRIMARY_500-BORDER PRIMARY_500-CLR'
              : 'hover:bg-blue-100 cursor-pointer border-l border-b GRAY_500-BORDER'
          )}>
          Unpaid
        </div>
      </div>
      <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG items-center z-1 ">
        {columns.map((column, i) => {
          const invsSelected =
            selectedInvoicesOption === 'UNPAID' ? invoices.unPaidInvoices : invoices.paidInvoices;
          return (
            <div
              key={column.id}
              style={{ flex: column.width / totalWidth }}
              className={twMerge('flex py-3 mx-1')}>
              {column.id === 'select' && (
                <AppCheckBox
                  isChecked={
                    filteredInvoices.length > 0 && selectedInvoices.length === invsSelected.length
                  }
                  onClick={(isChecked: boolean) => {
                    if (isChecked) {
                      setSelectedInvoices(filteredInvoices);
                    } else {
                      setSelectedInvoices([]);
                    }
                  }}
                />
              )}

              {column.id !== 'select' && column.id !== 'actions' ? (
                <div className="flex align-center">
                  <p className="text-xs TEXT_SECONDARY-CLR mr-2">{column.name}</p>
                </div>
              ) : (
                <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
              )}
            </div>
          );
        })}
      </div>
      {filteredInvoices.length === 0 ? (
        <div className="w-full flex items-center justify-center max-h-[500px]" style={tableStyle}>
          <div className="flex flex-col items-center mb-2">
            <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
            <p>{`No ${selectedInvoicesOption === 'PAID' ? 'Paid' : 'Unpaid'} Invoices`}</p>
          </div>
        </div>
      ) : (
        <div>
          <div className="max-h-[500px] overflow-scroll" style={tableStyle}>
            {filteredInvoices.length > 0 &&
              filteredInvoices.map((filteredInvoice, index) => {
                const invoice = usedInvoices
                  ? usedInvoices.find((inv) => inv.invoiceNumber === filteredInvoice.docNumber)
                  : null;
                return (
                  <div
                    key={index + 'oo'}
                    className="w-full flex items-center  px-4 justify-between">
                    {columns.map((column, i) => {
                      return (
                        <div
                          key={column.id}
                          style={{ flex: column.width / totalWidth }}
                          className={`flex py-3 mx-1 ${
                            columns.length - 1 !== i ? 'overflow-hidden' : ''
                          }`}>
                          {column.id === 'select' && (
                            <AppCheckBox
                              isChecked={selectedInvoices.some(
                                (obj) => obj.id === filteredInvoice.id
                              )}
                              onClick={(isChecked: boolean) => {
                                if (isChecked) {
                                  const combInvoices = [...selectedInvoices];
                                  combInvoices.push(filteredInvoice);
                                  setSelectedInvoices(combInvoices);
                                } else {
                                  const combInvoices = [...selectedInvoices].filter(
                                    (inv) => inv.id !== filteredInvoice.id
                                  );
                                  setSelectedInvoices(combInvoices);
                                }
                              }}
                            />
                          )}
                          {column.id === 'invoiceDate' && (
                            <p className="text-sm capitalize w-full">
                              {filteredInvoice.createdAt
                                ? moment(filteredInvoice.createdAt).utc().format('MM/DD/YYYY')
                                : ''}
                            </p>
                          )}
                          {column.id === 'invoiceNumber' && (
                            <p className="px-2 text-sm capitalize w-full">
                              {filteredInvoice.docNumber ? `${filteredInvoice.docNumber}` : ''}
                            </p>
                          )}
                          {column.id === 'amount' && (
                            <div className="flex w-full flex-col">
                              <p className="text-sm w-full ">{`${
                                filteredInvoice.amount
                                  ? formatMoney(filteredInvoice.amount ?? '', 2)
                                  : ''
                              }`}</p>
                            </div>
                          )}
                          {column.id === 'alreadyOn' && invoice && (
                            <div className="flex items-center rounded-full px-2 py-1 PRIMARY_500-BG">
                              <p className="text-[11px] w-full WHITE-CLR capitalize font-semibold">
                                {`${invoice.type.split('_').join(' ').toLowerCase()} ${
                                  invoice.date ? `| ${invoice.date}` : ''
                                }`}
                              </p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
          {filteredInvoices.length > 0 && (
            <div className="flex p-2 border-t flex-row-reverse">
              <div>{`Total:  ${formatMoney(totalAmount ?? '', 2)}`}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
