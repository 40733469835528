import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { SupplierReleaseApiPostBody, SupplierReleaseUpdate } from '@dill/dill-shared';
import { firebaseFileUploadHelper } from '../../utils/helpers';

export const createSupplierRelease = createAsyncThunk(
  'supplierReleases/createSupplierRelease',
  async (
    {
      details: { releaseDetails, storagePathName, releasePdf },
    }: {
      details: {
        releaseDetails: SupplierReleaseApiPostBody;
        storagePathName: string;
        releasePdf: string;
      };
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const downloadUrl = await firebaseFileUploadHelper({
        blobUrl: releasePdf,
        fileNameWithExtension: `${new Date().getTime()}-signed.pdf`,
        storagePath: storagePathName,
      });

      const releaseData = {
        ...releaseDetails,
        previewPdfUrl: downloadUrl ?? '',
      };

      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/suppliers/releases/createSupplierRelease`,
        { ...releaseData },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierReleases = createAsyncThunk(
  'supplierReleases/getSupplierReleases',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/releases/getSupplierReleases`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierRelease = createAsyncThunk(
  'supplierReleases/getSupplierRelease',
  async (
    {
      supplierReleaseId,
    }: {
      supplierReleaseId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/releases/getSupplierRelease/${supplierReleaseId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateSupplierRelease = createAsyncThunk(
  'supplierReleases/updateSupplierRelease',
  async (
    {
      supplierReleaseId,
      releaseDetails,
      storagePathName,
      releasePdfUrl,
    }: {
      supplierReleaseId: string;
      releaseDetails: SupplierReleaseUpdate;
      storagePathName?: string;
      releasePdfUrl?: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      let releaseData = releaseDetails;
      if (storagePathName && releasePdfUrl) {
        const uploadUrl = await firebaseFileUploadHelper({
          blobUrl: releasePdfUrl,
          fileNameWithExtension: `${new Date().getTime()}-signed.pdf`,
          storagePath: storagePathName,
        });
        releaseData = {
          ...releaseDetails,
          previewPdf: {
            name: '',
            url: uploadUrl ?? '',
          },
        };
      }

      const response = await axios.put<AppServerResponse>(
        BASE_API_URL + `/suppliers/releases/updateSupplierRelease/${supplierReleaseId}`,
        {
          ...releaseData,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const deleteSupplierRelease = createAsyncThunk(
  'supplierReleases/deleteSupplierRelease',
  async (
    {
      supplierReleaseId,
    }: {
      supplierReleaseId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.delete<AppServerResponse>(
        `${BASE_API_URL}/suppliers/releases/deleteSupplierRelease/${supplierReleaseId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierJobReleases = createAsyncThunk(
  'supplierReleases/getSupplierJobReleases',
  async (
    {
      supplierJobId,
      buyerId,
    }: {
      supplierJobId: string;
      buyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/releases/getSupplierJobReleases`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          params: { supplierJobId, buyerId },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
