import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  createSupplierRelease,
  deleteSupplierRelease,
  getSupplierJobReleases,
  getSupplierRelease,
  getSupplierReleases,
  updateSupplierRelease,
} from '../services/supplierReleasesService';
import { SupplierReleasesInitialState } from '../globalSlices/supplierReleasesSlice';

export const createSupplierReleaseExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierReleasesInitialState>
) => {
  const reducerId = 'createSupplierRelease';
  builder.addCase(createSupplierRelease.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createSupplierRelease.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createSupplierRelease.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getSupplierReleasesExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierReleasesInitialState>
) => {
  const reducerId = 'getSupplierReleases';
  builder.addCase(getSupplierReleases.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
  });
  builder.addCase(getSupplierReleases.fulfilled, (state, action: PayloadAction<any | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload.success && action.payload.data) {
      state.supplierReleases = action.payload.data;
    }
  });
  builder.addCase(getSupplierReleases.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getSupplierReleases: action.payload }]),
      ];
    }
  });
};

export const getSupplierReleaseExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierReleasesInitialState>
) => {
  const reducerId = 'getSupplierRelease';
  builder.addCase(getSupplierRelease.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
    state.selectedSupplierRelease = null;
  });
  builder.addCase(
    getSupplierRelease.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload.data) {
        state.selectedSupplierRelease = action.payload.data;
      }
    }
  );
  builder.addCase(getSupplierRelease.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getSupplierRelease: action.payload }]),
      ];
    }
  });
};

export const updateSupplierReleaseExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierReleasesInitialState>
) => {
  const reducerId = 'updateSupplierRelease';
  builder.addCase(updateSupplierRelease.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateSupplierRelease.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(updateSupplierRelease.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const deleteSupplierReleaseExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierReleasesInitialState>
) => {
  const reducerId = 'deleteSupplierRelease';
  builder.addCase(deleteSupplierRelease.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    deleteSupplierRelease.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(deleteSupplierRelease.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getSupplierJobReleasesExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierReleasesInitialState>
) => {
  const reducerId = 'getSupplierJobReleases';
  builder.addCase(getSupplierJobReleases.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getSupplierJobReleases.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(getSupplierJobReleases.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
