import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import { P21InitialState } from '../globalSlices/p21Slice';
import { getP21Invoices } from '../services/p21Service';

export const getP21InvoicesExtraReducer = (builder: ActionReducerMapBuilder<P21InitialState>) => {
  const reducerId = 'getP21Invoices';
  builder.addCase(getP21Invoices.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getP21Invoices.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      state.p21Invoices = action.payload?.data;
    }
  );
  builder.addCase(getP21Invoices.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
