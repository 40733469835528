import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COLORS } from '../../../../../utils/colors';
import { AppButton } from '../../../../general/AppButton/AppButton';
import emptyListImg from '../../../../../assets/images/emptyList.png';
import errorCircle from '../../../../../assets/svg/error_circle.svg';
import { Eye, Edit3 } from 'react-feather';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import moment from 'moment';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import {
  getLienBuyerReleases,
  deleteRelease,
  updateReleaseByMainBuyer,
  getMainBuyerReleaseHistory,
} from '../../../../../redux/services/liensService';
import { twMerge } from 'tailwind-merge';
import {
  CheckCircle,
  DownloadSimple,
  Timer,
  Warning,
  ClockClockwise,
  Calendar,
  CaretLeft,
  CaretRight,
  ArrowsDownUp,
  SortDescending,
  SortAscending,
  DotsThreeVertical,
  ArrowElbowUpRight,
  ArrowElbowUpLeft,
  Prohibit,
  Trash,
  UploadSimple,
} from 'phosphor-react';
import { AppPdfPreviewModal } from '../../../../general/AppPdfPreviewModal/AppPdfPreviewModal';
import {
  DillLinkedTransaction,
  DillTransaction,
  LienApiResponse,
  formatMoney,
} from '@dill/dill-shared';
import { AppCheckBox } from '../../../../general/AppCheckBox/AppCheckBox';
import { selectBuyerLiens, unSelectBuyerLiens } from '../../../../../redux/globalSlices/liensSlice';
import { AppRangeCalender } from '../../../../general/AppRangeCalender/AppRangeCalender';
import ReactPaginate from 'react-paginate';
import AppCustomDropdown from '../../../../general/AppCustomDropdown/AppCustomDropdown';
import AppUploadFileModal from '../../../../general/AppUploadFileModal/AppUploadFileModal';
import { AppSuccessModal } from '../../../../general/AppSuccessModal/AppSuccessModal';
import { useDropzone } from 'react-dropzone';
import AppReleaseStatusPill from '../../../../general/AppReleaseStatusPill/AppReleaseStatusPill';
import { AppDeleteModal } from '../../../../general/AppDeleteModal/AppDeleteModal';
import AppNotesModal from '../../../../general/AppNotesModal/AppNotesModal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { downloadFile, disablePDFFields, openDillToast } from '../../../../../utils/helpers';
import { AppReleaseHistoryModal } from '../../../../general/AppReleaseHistoryModal/AppReleaseHistoryModal';
import { InvoiceReference } from '../../../../../constants/invoice';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';

const BuyerLienReleasesTable = ({
  releases,
  refresh,
}: {
  releases: LienApiResponse[];
  refresh: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [showPreviewPdf, setShowPreviewPdf] = useState(false);
  const [selectedRelease, setSelectedRelease] = useState<LienApiResponse | null>(null);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const { selectedBuyerLiens, selectedReleaseHistory } = useAppSelector((state) => state.liens);
  const [releasePreviewDetails, setReleasePreviewDetails] = useState(
    {} as {
      recipient: string;
      type: string;
      paymentAmount: number | null;
      throughDate: string | Date;
      job: string;
      status:
        | 'Declined'
        | 'Rejected'
        | 'Requested'
        | 'Signed'
        | 'Scheduled'
        | 'Pending'
        | 'Sent to GC';
      declineReason?: string | null;
      releaseAttachments?: { name: string; url: string }[] | null;
      notes?: string | null;
      dueDate: string | Date;
      signedTime?: Date | null;
      signedBy?: string | null;
      transactions?: DillTransaction[] | DillLinkedTransaction[] | null;
      sentToGCTime?: Date | null;
      sentToGCEmails?: string[] | null;
      invoiceList?: InvoiceReference[] | null;
    }
  );
  const [openCalenderRange, setOpenCalenderRange] = useState(false);
  const [dateRange, setDateRange] = useState<{ startDate: string; endDate: string } | null>(null);
  const [sort, setSort] = useState('');
  const [sorted, setSorted] = useState(false);
  const [sortedRealeases, setSortedReleases] = useState<LienApiResponse[]>([]);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [isSignSuccess, setIsSignSuccess] = useState(false);
  const [openDeclineReleaseModal, setOpenRejectReleaseModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState({} as File);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);
  const [openBackToSigned, setOpenBackToSigned] = useState(false);
  const [openSuccessBackToSigned, setOpenSuccessBackToSigned] = useState(false);
  const [refreshRelease, setRefreshRelease] = useState(false);
  const [openRejectSuccessModal, setOpenRejectSuccessModal] = useState(false);
  const [openRejectErrorModal, setOpenRejectErrorModal] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [openDropdown, setOpenDropDown] = useState('');
  const [openShowHistory, setOpenShowHistory] = useState(false);

  // Pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 20; // Number of items per page
  const pageCount = Math.ceil(releases.length / itemsPerPage);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = sortedRealeases.slice(itemOffset, endOffset);
  const currentDate = new Date();
  const endOfMonthCurrent = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const startOfMonthThreeMonthsAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    1
  );

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % releases.length;
    setItemOffset(newOffset);
  };
  const cummulativeOffset = useMemo(() => {
    let result = 0;
    const currentTotalItems = endOffset / itemsPerPage;
    if (pageCount === currentTotalItems) {
      result = releases.length;
    } else if (releases.length === 0) {
      result = 0;
    } else {
      const itemsTotal = currentTotalItems * itemsPerPage;
      result = itemsTotal;
    }
    return result;
  }, [endOffset, releases]);

  useEffect(() => {
    setSortedReleases(releases);
    setItemOffset(0);
    setCurrentPage(1);
  }, [releases]);

  useEffect(() => {
    if (dateRange && selectedMainBuyerId) {
      const selectedDateRange = {
        startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
        endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
      };
      dispatch(getLienBuyerReleases({ ...selectedDateRange, mainBuyerId: selectedMainBuyerId }));
    } else {
      setDateRange({
        startDate: moment(startOfMonthThreeMonthsAgo).format('YYYY-MM-DD'),
        endDate: moment(endOfMonthCurrent).format('YYYY-MM-DD'),
      });
    }
    return () => {};
  }, [dateRange, refresh, refreshRelease, selectedMainBuyerId]);

  const fetchPdf = async (url: string) => {
    const res = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    if (res.data) {
      return res.data;
    }
    return null;
  };

  const columns = useMemo(() => {
    return [
      { id: 'select', name: '', width: 50, cellType: 'VIEW_TEXT' },
      { id: 'recipient', name: 'Recipient', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'requestDate', name: 'Request Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'throughDate', name: 'Through Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'dueDate', name: 'Due Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'template', name: 'Template', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'job', name: 'Job', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'status', name: 'Status', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 150, cellType: 'VIEW_TEXT' },
    ];
  }, []);
  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );
  const handleDownloadSelectedPdf = async (release: LienApiResponse) => {
    if (release) {
      let flattenedPDF;
      const createdAtTimeStamp = release?.createdAt
        ? new Date(release.createdAt).getTime()
        : new Date().getTime();
      if (release?.status === 'Signed' || release?.status === 'Sent to GC') {
        flattenedPDF = release?.signedPdf?.url;
      } else {
        flattenedPDF = await disablePDFFields(release?.previewPdf?.url ?? '');
      }
      downloadFile(
        flattenedPDF || '',
        `${release.job?.name ?? ''}-${release.subSupplier?.name ?? ''}-${createdAtTimeStamp}.pdf`
      );
    }
  };
  const handlePreviewPdf = async (release: LienApiResponse) => {
    const paymentAmountFieldData = release.fieldsData?.find((item) => item.id === 'paymentAmount');
    const throughDateFieldData = release.fieldsData?.find((item) => item.id === 'throughDate');
    setReleasePreviewDetails({
      recipient: `${
        release.recipient?.subUser?.firstName ?? release.recipient?.user?.firstName ?? ''
      } ${release.recipient?.subUser?.lastName ?? release.recipient?.user?.lastName ?? ''}`,
      type: release?.type?.toLowerCase() ?? '',
      paymentAmount: paymentAmountFieldData ? Number(paymentAmountFieldData.value) : null,
      throughDate: throughDateFieldData ? throughDateFieldData.value : '',
      job: release.job?.name ?? '',
      status: release.status,
      declineReason: release?.declineReason,
      releaseAttachments: release?.releaseAttachments,
      notes: release?.notes,
      dueDate: release?.dueDate ?? '',
      signedTime: release.signedTime,
      signedBy:
        release.signedBy?.subUser?.firstName || release.signedBy?.user?.firstName
          ? `${release.signedBy?.subUser?.firstName ?? release.signedBy?.user?.firstName ?? ''} ${
              release.signedBy?.subUser?.lastName ?? release.signedBy?.user?.lastName ?? ''
            }`
          : null,
      transactions: release?.transactions ?? null,
      sentToGCTime: release?.sentToGCTime ?? null,
      sentToGCEmails: release?.sentToGCEmails ?? null,
      invoiceList: release?.invoiceList || null,
    });
    setShowPreviewPdf(true);
  };

  const handleSelectDateRange = (range: { start: Date; finish: Date }) => {
    const endDateString = new Date(range.finish);
    const endOfMonth = moment(endDateString).endOf('day').format('YYYY-MM-DD LT');
    setDateRange({
      startDate: moment(range.start).format('YYYY-MM-DD LT'),
      endDate: endOfMonth,
    });
  };

  const sortByProperty = (
    sortType: 'asc' | 'desc',
    sortKey: string,
    getter: (item: any) => any,
    compare?: (a: any, b: any) => number
  ) => {
    const filteredReleases = releases.filter((release) => {
      const value = getter(release);
      return value !== undefined && value !== null;
    });

    const sortedReleasesCopy = [...filteredReleases];
    sortedReleasesCopy.sort((a, b) => {
      const valueA = getter(a);
      const valueB = getter(b);

      if (compare) {
        return compare(valueA, valueB) * (sortType === 'asc' ? 1 : -1);
      } else {
        const stringA = String(valueA).toUpperCase();
        const stringB = String(valueB).toUpperCase();

        if (stringA < stringB) {
          return sortType === 'asc' ? -1 : 1;
        } else if (stringA > stringB) {
          return sortType === 'asc' ? 1 : -1;
        }

        return 0;
      }
    });

    const undefinedOrNullReleases = releases.filter((release) => {
      const value = getter(release);
      return value === undefined || value === null;
    });

    const sortedReleases = sortedReleasesCopy.concat(undefinedOrNullReleases);

    setSortedReleases(sortedReleases);
  };

  useEffect(() => {
    if (location.pathname.startsWith(`/buyerLienReleases/${selectedMainBuyerId}/overview`)) {
      const searchParams = new URLSearchParams(location.search);
      const releaseId = searchParams.get('releaseId');
      if (releaseId) {
        const lienRelease = releases.find((rel) => rel.id === releaseId);
        if (lienRelease) {
          setSelectedRelease(lienRelease);
          handlePreviewPdf(lienRelease);
        }
      }
    }
  }, [location.pathname, releases]);

  const handleSort = (value: string) => {
    if (releases) {
      if (sort === value) {
        if (sorted) {
          setSortedReleases(releases);
          setSort('');
          setSorted(false);
        } else {
          if (value === 'recipient') {
            sortByProperty('desc', value, (release) => release.subSupplier?.name);
          } else if (value === 'requestDate') {
            sortByProperty('desc', value, (release) => release?.requestedAt);
          } else if (value === 'throughDate') {
            sortByProperty('desc', value, (release) => {
              const throughDateFieldData = release.fieldsData?.find(
                (item: any) => item.id === 'throughDate'
              );
              return throughDateFieldData?.value;
            });
          } else if (value === 'dueDate') {
            sortByProperty('desc', value, (release) => release.dueDate);
          } else if (value === 'status') {
            sortByProperty('desc', value, (release) => release.status);
          } else if (value === 'amount') {
            sortByProperty(
              'desc',
              value,
              (release) => {
                const paymentAmountFieldData = release.fieldsData?.find(
                  (item: any) => item.id === 'paymentAmount'
                );
                return paymentAmountFieldData?.value;
              },
              (a, b) => a - b
            );
          } else if (value === 'job') {
            sortByProperty(
              'desc',
              value,
              (release) => release.job?.number ?? 0,
              (a, b) => a - b
            );
          } else if (value === 'template') {
            sortByProperty('desc', value, (release) => release.template?.name);
          }

          setSorted(true);
        }
      } else {
        if (value === 'recipient') {
          sortByProperty('asc', value, (release) => release.subSupplier?.name);
        } else if (value === 'requestDate') {
          sortByProperty('asc', value, (release) => release.requestedAt);
        } else if (value === 'throughDate') {
          sortByProperty('asc', value, (release) => {
            const throughDateFieldData = release.fieldsData?.find(
              (item: any) => item.id === 'throughDate'
            );
            return throughDateFieldData?.value;
          });
        } else if (value === 'dueDate') {
          sortByProperty('asc', value, (release) => release.dueDate);
        } else if (value === 'status') {
          sortByProperty('asc', value, (release) => release.status);
        } else if (value === 'amount') {
          sortByProperty(
            'asc',
            value,
            (release) => {
              const paymentAmountFieldData = release.fieldsData?.find(
                (item: any) => item.id === 'paymentAmount'
              );
              return paymentAmountFieldData?.value;
            },
            (a, b) => a - b
          );
        } else if (value === 'job') {
          sortByProperty(
            'asc',
            value,
            (release) => release.job?.number ?? 0,
            (a, b) => a - b
          );
        } else if (value === 'template') {
          sortByProperty('asc', value, (release) => release.template?.name);
        }

        setSort(value);
        setSorted(false);
      }
    }
  };

  const handleUploadSignedRelease = async (file: File) => {
    const blobUrl = URL.createObjectURL(file);
    if (selectedRelease && selectedMainBuyerId) {
      const currentTimeInDesiredTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const result = await dispatch(
        updateReleaseByMainBuyer({
          mainBuyerId: selectedMainBuyerId,
          releaseId: selectedRelease.id ?? '',
          releaseDetails: {
            status: 'Signed',
            signedTime: new Date(),
            signedById: null,
          },
          timezone: currentTimeInDesiredTimeZone,
          storagePathName: `buyerReleases/${selectedRelease.buyer?.id}/${selectedRelease.subSupplier?.id}/${selectedRelease.job?.id}`,
          releasePdfUrl: blobUrl,
          signedPdfName: file.name,
        })
      );
      if (result.type === 'liens/updateReleaseByMainBuyer/fulfilled') {
        setIsSignSuccess(true);
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setOpenUploadFile(true);
    }
  };

  const openFilePicker = (index: string) => {
    const fileInput = document.getElementById(`fileInput_${index}`) as HTMLInputElement;
    fileInput.click();
  };

  const handleSendToGG = async (release: LienApiResponse) => {
    const sendToGCResult = await dispatch(
      updateReleaseByMainBuyer({
        mainBuyerId: selectedMainBuyerId || '',
        releaseId: release.id,
        releaseDetails: {
          status: 'Sent to GC',
        },
      })
    );
    if (sendToGCResult.type === 'liens/updateReleaseByMainBuyer/fulfilled') {
      openDillToast({ message: 'Status updated to ‘Sent to GC’', type: 'SUCCESS' });
      setRefreshRelease(!refreshRelease);
    } else {
      openDillToast({ message: 'Failed to update release to ‘Sent to GC’', type: 'ERROR' });
    }
  };

  const handleDropdownOption = (value: string, release: LienApiResponse) => {
    switch (value) {
      case 'upload':
        openFilePicker(release.id);
        break;
      case 'edit':
        navigate(`editRelease/${release.id}`);
        break;
      case 'delete':
        setConfirmDelete(true);
        break;
      case 'sent to gc':
        handleSendToGG(release);
        break;
      case 'back to signed':
        setOpenBackToSigned(true);
        break;
      case 'reject':
        setOpenRejectReleaseModal(true);
        break;
      // case 'download':
      //   if (release) {
      //     handleDownloadSelectedPdf(release);
      //   }
      //   break;
      default:
        break;
    }
  };
  const handleDeleteRelease = async () => {
    const deleteReleaseResult = await dispatch(
      deleteRelease({
        releaseId: selectedRelease?.id ?? '',
        mainBuyerId: selectedMainBuyerId || '',
      })
    );
    setConfirmDelete(false);
    if (deleteReleaseResult.type === 'liens/deleteRelease/fulfilled') {
      setSuccessDelete(true);
    } else {
      setErrorDelete(true);
    }
  };

  const handleBackToSigned = async () => {
    const sendToGCResult = await dispatch(
      updateReleaseByMainBuyer({
        mainBuyerId: selectedMainBuyerId || '',
        releaseId: selectedRelease?.id ?? '',
        releaseDetails: {
          status: 'Signed',
          sentToGCTime: null,
          sentToGCEmails: null,
        },
      })
    );
    setOpenBackToSigned(false);
    if (sendToGCResult.type === 'liens/updateReleaseByMainBuyer/fulfilled') {
      setOpenSuccessBackToSigned(true);
    } else {
      setOpenSuccessBackToSigned(true);
    }
  };

  const handleRejectRelease = async (value: string) => {
    const supplierTemplateField = selectedRelease?.template?.formFields.filter(
      (field) => field.whoPopulatesField === 'SUB_SUPPLIER'
    );
    const adjustedFieldData = [];
    if (selectedRelease?.fieldsData) {
      for (let index = 0; index < selectedRelease?.fieldsData.length; index++) {
        const fieldData = selectedRelease?.fieldsData[index];
        const foundSupplier = supplierTemplateField?.find((temp) => temp.id === fieldData.id);
        if (!foundSupplier) {
          adjustedFieldData.push(fieldData);
        }
      }
    }
    setOpenRejectReleaseModal(false);
    setDeclineReason(value);
    const result = await dispatch(
      updateReleaseByMainBuyer({
        mainBuyerId: selectedMainBuyerId || '',
        releaseId: selectedRelease?.id ?? '',
        releaseDetails: {
          status: 'Rejected',
          declineReason: value,
          fieldsData: adjustedFieldData,
        },
      })
    );
    setOpenRejectReleaseModal(false);
    if (result.type === 'liens/updateReleaseByMainBuyer/fulfilled') {
      setOpenRejectSuccessModal(true);
    } else {
      setOpenRejectErrorModal(true);
    }
  };

  const handleShowHistory = async () => {
    const releaseHisResult = await dispatch(
      getMainBuyerReleaseHistory({
        releaseId: selectedRelease?.id ?? '',
        mainBuyerId: selectedMainBuyerId || '',
      })
    );
    if (releaseHisResult.type === 'releases/getMainBuyerReleaseHistory/fulfilled') {
      setOpenShowHistory(true);
    }
  };

  return (
    <div className="w-full h-5/6 flex flex-col">
      <AppReleaseHistoryModal
        open={openShowHistory}
        handleClose={() => setOpenShowHistory(false)}
        history={selectedReleaseHistory}
      />
      <AppNotesModal
        open={openDeclineReleaseModal}
        type="buyer"
        onSubmit={handleRejectRelease}
        handleClose={() => setOpenRejectReleaseModal(false)}
      />
      <div className="flex flex-row items-center">
        <div className="text-sm">Created Date</div>
        <button
          onClick={() => setOpenCalenderRange(true)}
          className="flex flex-row items-center GREY_300-BORDER border ml-2 rounded py-1 px-2 GREY_100-BG hover:bg-slate-200">
          <Calendar className="text-slate-500 mr-2" size={20} />
          <div className="text-sm TEXT_PRIMARY-CLR">{`${
            dateRange?.startDate
              ? moment(dateRange?.startDate).format('MMM YYYY')
              : moment(startOfMonthThreeMonthsAgo).format('MMMM YYYY')
          }-${
            dateRange?.endDate
              ? moment(dateRange?.endDate).format('MMM YYYY')
              : moment(endOfMonthCurrent).format('MMMM YYYY')
          }`}</div>
        </button>
      </div>
      <div className="w-full h-full flex flex-col border rounded-lg  overflow-y-scroll mt-4">
        <AppRangeCalender
          open={openCalenderRange}
          handleClose={() => setOpenCalenderRange(false)}
          onSelectDate={handleSelectDateRange}
          dateRange={{
            start: dateRange?.startDate
              ? moment(dateRange?.startDate).format('DD MMM YYYY')
              : moment(startOfMonthThreeMonthsAgo).format('DD MMMM YYYY'),
            finish: dateRange?.endDate
              ? moment(dateRange?.endDate).format('DD MMMM YYYY')
              : moment(endOfMonthCurrent).format('DD MMMMM YYYY'),
          }}
        />
        <AppPdfPreviewModal
          open={showPreviewPdf}
          handleClose={() => {
            setShowPreviewPdf(false);
            if (
              location.pathname.startsWith(`/buyerLienReleases/${selectedMainBuyerId}/overview`)
            ) {
              const searchParams = new URLSearchParams(location.search);
              const releaseId = searchParams.get('releaseId');
              if (releaseId) {
                navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
              }
            }
          }}
          invoice={''}
          pdfType="RELEASE"
          pdfList={[
            {
              name: 'lien-release',
              url:
                selectedRelease?.status === 'Signed' || selectedRelease?.status === 'Sent to GC'
                  ? selectedRelease?.signedPdf?.url || ''
                  : selectedRelease?.previewPdf?.url || '',
              date: new Date(),
            },
          ]}
          handleDownloadPdf={() => {
            if (selectedRelease) {
              handleDownloadSelectedPdf(selectedRelease);
            }
          }}
          isSigned={false}
          releaseDetails={releasePreviewDetails}
          mode="Buyer"
          onShowHistory={handleShowHistory}
          // showHistoryButton={!openBulkReleasesModal}
        />
        <AppUploadFileModal
          open={openUploadFile}
          handleClose={() => {
            setOpenUploadFile(false);
          }}
          title="Upload Signed Release"
          secondaryText="The status of this release will update  to “Signed,” and you’ll be able to access and download this signed release in your Dill dashboard."
          onSelectFile={handleUploadSignedRelease}
          selectedFile={selectedFile}
        />
        <AppSuccessModal
          open={isSignSuccess}
          handleClose={() => {
            setIsSignSuccess(false);
            if (selectedMainBuyerId) {
              if (dateRange) {
                dispatch(getLienBuyerReleases({ ...dateRange, mainBuyerId: selectedMainBuyerId }));
              } else {
                dispatch(
                  getLienBuyerReleases({
                    mainBuyerId: selectedMainBuyerId,
                    startDate: moment(startOfMonthThreeMonthsAgo).format('YYYY-MM-DD'),
                    endDate: moment(endOfMonthCurrent).format('YYYY-MM-DD'),
                  })
                );
              }
            }
          }}
          title="Successfully uploaded signed release!"
          subTitle="The status of this release is now “Signed”"
          buttonText="View All Lien Releases"
        />
        <AppSuccessModal
          open={successDelete}
          handleClose={() => {
            setSuccessDelete(false);
            if (selectedMainBuyerId) {
              if (dateRange) {
                dispatch(getLienBuyerReleases({ ...dateRange, mainBuyerId: selectedMainBuyerId }));
              } else {
                dispatch(
                  getLienBuyerReleases({
                    mainBuyerId: selectedMainBuyerId,
                    startDate: moment(startOfMonthThreeMonthsAgo).format('YYYY-MM-DD'),
                    endDate: moment(endOfMonthCurrent).format('YYYY-MM-DD'),
                  })
                );
              }
            }
          }}
          title="Successfully deleted lien release!"
          subTitle="This release is now gone from your dashboard"
          buttonText="View All Lien Releases"
        />

        <AppSuccessModal
          open={openRejectSuccessModal}
          handleClose={() => {
            setOpenRejectSuccessModal(false);
            setDeclineReason('');
            if (selectedMainBuyerId) {
              if (dateRange) {
                dispatch(getLienBuyerReleases({ ...dateRange, mainBuyerId: selectedMainBuyerId }));
              } else {
                dispatch(
                  getLienBuyerReleases({
                    mainBuyerId: selectedMainBuyerId,
                    startDate: moment(startOfMonthThreeMonthsAgo).format('YYYY-MM-DD'),
                    endDate: moment(endOfMonthCurrent).format('YYYY-MM-DD'),
                  })
                );
              }
            }
          }}
          title="Successfully rejected lien release!"
          subTitle="This release is now rejected"
          buttonText="View All Lien Releases"
        />
        <AppSuccessModal
          open={openRejectErrorModal}
          modalImage={errorCircle}
          title="Error rejecting release!"
          subTitle="Your lien release request failed to reject. Contact customer support"
          buttonText="Close"
          secondButtonText="Retry"
          isSecondButton={true}
          onSecondButtonClick={() => {
            setOpenRejectErrorModal(false);
            handleRejectRelease(declineReason);
          }}
          handleClose={() => {
            setOpenRejectErrorModal(false);
          }}
        />
        <AppSuccessModal
          open={errorDelete}
          modalImage={errorCircle}
          title="Error deleting release!"
          subTitle="Your lien release request failed to delete. Contact customer support"
          buttonText="Close"
          secondButtonText="Retry"
          isSecondButton={true}
          onSecondButtonClick={() => {
            setErrorDelete(false);
            handleDeleteRelease();
          }}
          handleClose={() => {
            setErrorDelete(false);
          }}
        />
        <AppSuccessModal
          open={openBackToSigned}
          type="warning"
          title="Are you sure you want to update release status back to “Signed”?"
          subTitle="The status of this release will be updated to “Signed” in your Dill dashboard"
          buttonText="No, cancel"
          secondButtonText="Yes, I’m sure"
          isSecondButton={true}
          onSecondButtonClick={() => {
            handleBackToSigned();
          }}
          handleClose={() => {
            setOpenBackToSigned(false);
          }}
        />
        <AppSuccessModal
          open={openSuccessBackToSigned}
          title="Successfully updated release status!"
          subTitle="The status of this release is now “Signed”"
          buttonText="Back to All Releases"
          handleClose={() => {
            setOpenSuccessBackToSigned(false);
            setRefreshRelease(!refreshRelease);
          }}
        />
        <AppDeleteModal
          open={confirmDelete}
          handleClose={() => setConfirmDelete(false)}
          title="Are you sure you want to delete this lien release?"
          subTitle="This will cancel the request to your vendor and remove any link to payments on Dill. This action can’t be undone"
          buttonText="No, cancel"
          secondButtonText="Yes, I’m sure"
          onSecondButtonClick={handleDeleteRelease}
        />
        <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG items-center z-10">
          {columns.map((column, i) => {
            return (
              <div
                key={column.id}
                style={{ flex: column.width / totalWidth }}
                className={twMerge(
                  'flex py-3 mx-1',
                  i === 2 || i === 3 || i === 4 || i === 5 ? 'justify-center' : 'items-center'
                )}>
                {column.id === 'select' && (
                  <AppCheckBox
                    isChecked={
                      selectedBuyerLiens.length !== 0 &&
                      selectedBuyerLiens.length === releases.length
                    }
                    onClick={(isChecked: boolean) => {
                      if (isChecked) {
                        dispatch(selectBuyerLiens(releases));
                      } else {
                        dispatch(unSelectBuyerLiens(releases));
                      }
                    }}
                  />
                )}

                {column.id !== 'select' && column.id !== 'actions' ? (
                  <div
                    onClick={() => handleSort(column.id)}
                    className="cursor-pointer flex align-center">
                    <p className="text-xs TEXT_SECONDARY-CLR mr-2">{column.name}</p>
                    <div>
                      {sort === column.id && sorted ? (
                        <SortAscending color={COLORS.GREY_400} />
                      ) : sort === column.id && !sorted ? (
                        <SortDescending color={COLORS.GREY_400} />
                      ) : (
                        <ArrowsDownUp color={COLORS.GREY_400} />
                      )}
                    </div>
                  </div>
                ) : (
                  <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
                )}
              </div>
            );
          })}
        </div>
        {releases.length === 0 && (
          <div className="w-full h-full flex items-center justify-center">
            <div className="flex flex-col items-center">
              <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
              <p>No releases</p>
            </div>
          </div>
        )}
        {currentItems.length > 0 &&
          currentItems.map((lienRelease, index) => {
            const paymentAmountFieldData = lienRelease.fieldsData?.find(
              (item) => item.id === 'paymentAmount'
            );
            const throughDateFieldData = lienRelease.fieldsData?.find(
              (item) => item.id === 'throughDate'
            );
            return (
              <div key={index + 'oo'} className="w-full flex items-center  px-4 justify-between">
                {columns.map((column, i) => {
                  const recipientUser =
                    lienRelease.recipient?.subUser || lienRelease.recipient?.user;
                  return (
                    <div
                      key={column.id}
                      style={{ flex: column.width / totalWidth }}
                      className={`flex py-3 mx-1 ${
                        columns.length - 1 !== i ? 'overflow-hidden' : ''
                      }`}>
                      {column.id === 'select' && (
                        <AppCheckBox
                          isChecked={selectedBuyerLiens.some((obj) => obj.id === lienRelease.id)}
                          onClick={(isChecked: boolean) => {
                            if (isChecked) {
                              dispatch(selectBuyerLiens([lienRelease]));
                            } else {
                              dispatch(unSelectBuyerLiens([lienRelease]));
                            }
                          }}
                        />
                      )}
                      {column.id === 'recipient' && (
                        <div className="flex  w-full">
                          <div className="">
                            <div className="flex items-center">
                              <p className="text-sm capitalize ">
                                {lienRelease.subSupplier?.name || ''}
                              </p>
                              {!recipientUser?.firstName && !recipientUser?.lastName && (
                                <div
                                  data-tooltip-id={`release-error-tooltip_${i}`}
                                  className="flex p-1 ml-2 ERROR_100-BG ERROR_500-CLR justify-center items-center rounded-full cursor-pointer">
                                  <Warning size={18} weight="bold" />
                                </div>
                              )}
                              <ReactTooltip
                                id={`release-error-tooltip_${i}`}
                                place="top"
                                variant="dark"
                                opacity={'100%'}
                                style={{
                                  display: 'flex',
                                  background: '#334155',
                                  width: '300px',
                                  zIndex: '70',
                                  borderRadius: '8px',
                                  alignItems: 'center',
                                  justifyItems: 'center',
                                  fontWeight: '600',
                                }}>
                                <span>
                                  The original recipient on this release has been removed. Please
                                  edit and update the recipient
                                </span>
                              </ReactTooltip>
                            </div>
                            {(recipientUser?.firstName || recipientUser?.lastName) && (
                              <p className="text-2xs mt-1 capitalize text-slate-500">
                                {`${recipientUser?.firstName || ''} ${
                                  recipientUser?.lastName || ''
                                }`}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                      {column.id === 'requestDate' && (
                        <p className="text-sm capitalize w-full text-center">
                          {lienRelease?.requestedAt
                            ? moment(lienRelease?.requestedAt).format('MM/DD/YYYY')
                            : ''}
                        </p>
                      )}
                      {column.id === 'dueDate' && (
                        <p className="text-sm capitalize w-full text-center">
                          {lienRelease?.dueDate
                            ? moment(lienRelease?.dueDate).format('MM/DD/YYYY')
                            : ''}
                        </p>
                      )}
                      {column.id === 'throughDate' && (
                        <p className="text-sm capitalize w-full text-center">
                          {' '}
                          {throughDateFieldData && throughDateFieldData.value
                            ? moment(throughDateFieldData.value).format('MM/DD/YYYY')
                            : ''}
                        </p>
                      )}
                      {column.id === 'template' && (
                        <p className="px-2 text-xs capitalize w-full  text-center">
                          {lienRelease.template?.name || ''}
                        </p>
                      )}
                      {column.id === 'amount' && (
                        // <p className="text-sm w-full text-center">{`${formatMoney(
                        //   lienRelease.paymentAmount ?? '',
                        //   2
                        // )}`}</p>
                        <p className="text-sm w-full ">{`${
                          paymentAmountFieldData
                            ? formatMoney(paymentAmountFieldData.value ?? '', 2)
                            : ''
                        }`}</p>
                      )}

                      {column.id === 'job' && (
                        <div className="w-full flex flex-col">
                          <p className="text-xs capitalize font-bold">
                            {lienRelease.job?.number || ''}
                          </p>
                          <p className="text-xs capitalize ">{lienRelease.job?.name || ''}</p>
                          {lienRelease.job?.accountantEmails &&
                            lienRelease.job?.accountantEmails.length > 0 && (
                              <p className="text-xs text-slate-500">
                                {lienRelease.job?.accountantEmails.join(', ')}
                              </p>
                            )}
                          {lienRelease.job?.customerNumber && (
                            <p className="text-xs text-slate-500">
                              Customer: {lienRelease.job?.customerNumber || ''}
                            </p>
                          )}
                        </div>
                      )}
                      {column.id === 'status' && (
                        <div className="w-full  items-center">
                          <AppReleaseStatusPill status={lienRelease.status} />
                        </div>
                      )}
                      {column.id === 'actions' && (
                        <div>
                          <div className="flex w-40 items-center">
                            <AppButton
                              type="TERTIARY"
                              buttonStyles={{
                                padding: '8px',
                                height: '35px',
                                width: '35px',
                              }}
                              icon={<Eye color={COLORS.GREY_500} size={20} />}
                              onClick={() => {
                                setSelectedRelease(lienRelease);
                                handlePreviewPdf(lienRelease);
                                navigate(
                                  `/buyerLienReleases/${selectedMainBuyerId}/overview?releaseId=${lienRelease?.id}`
                                );
                              }}
                            />
                            <div className="w-2 h-2" />
                            <AppButton
                              type="TERTIARY"
                              buttonStyles={{
                                padding: '8px',
                                height: '35px',
                                width: '35px',
                                marginLeft: lienRelease.status === 'Requested' ? '4px' : '0px',
                              }}
                              icon={<DownloadSimple color={COLORS.GREY_500} size={20} />}
                              onClick={() => {
                                handleDownloadSelectedPdf(lienRelease);
                              }}
                            />
                            <div className="w-2 h-2" />
                            <input
                              id={`fileInput_${lienRelease.id}`}
                              type="file"
                              style={{ display: 'none' }}
                              accept="application/pdf"
                              onChange={handleFileChange}
                            />
                            {lienRelease.status !== 'Sent to GC' && (
                              <AppCustomDropdown
                                inputFieldStyles={{ width: '35px', marginLeft: '4px' }}
                                onSelect={(val) => {
                                  setSelectedRelease(lienRelease);
                                  handleDropdownOption(val, lienRelease);
                                }}
                                options={
                                  lienRelease.status === 'Signed' && lienRelease.template
                                    ? [
                                        {
                                          value: 'sent to gc',
                                          label: 'Mark As ‘Sent to GC’',
                                          icon: <ArrowElbowUpRight size={16} />,
                                        },
                                        {
                                          value: 'reject',
                                          label: 'Reject',
                                          icon: <Prohibit size={16} />,
                                          type: 'error',
                                        },
                                      ]
                                    : lienRelease.status === 'Signed' && !lienRelease.template
                                    ? [
                                        {
                                          value: 'sent to gc',
                                          label: 'Mark As ‘Sent to GC’',
                                          icon: <ArrowElbowUpRight size={16} />,
                                        },
                                      ]
                                    : [
                                        {
                                          value: 'upload',
                                          label: 'Upload Signed Release',
                                          icon: <UploadSimple size={16} />,
                                        },
                                        {
                                          value: 'edit',
                                          label: 'Edit',
                                          icon: <Edit3 size={16} />,
                                        },
                                        {
                                          value: 'delete',
                                          label: 'Delete',
                                          icon: <Trash size={16} />,
                                          type: 'error',
                                        },
                                      ]
                                }
                                open={openDropdown === lienRelease.id}
                                onOpen={() => setOpenDropDown(lienRelease.id)}
                                onClose={() => setOpenDropDown('')}
                              />
                            )}
                            {lienRelease.status === 'Sent to GC' && (
                              <AppCustomDropdown
                                inputFieldStyles={{ width: '35px', marginLeft: '4px' }}
                                onSelect={(val) => {
                                  setSelectedRelease(lienRelease);
                                  handleDropdownOption(val, lienRelease);
                                }}
                                options={[
                                  {
                                    value: 'back to signed',
                                    icon: <ArrowElbowUpLeft size={16} />,
                                    label: 'Revert back to ‘Signed’',
                                  },
                                ]}
                                open={openDropdown === lienRelease.id}
                                onOpen={() => setOpenDropDown(lienRelease.id)}
                                onClose={() => setOpenDropDown('')}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
      {/* Pagination component */}
      <div className="flex flex-row justify-between items-center w-3/5 mt-3">
        <div className="text-xs GREY_500-CLR">{`Showing ${
          releases.length !== 0 ? `${itemOffset + 1} to` : ''
        } ${cummulativeOffset} from ${releases.length} release${
          releases.length > 1 ? 's' : ''
        }`}</div>
        <ReactPaginate
          breakLabel="..."
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          forcePage={Math.min(Math.max(0, currentPage - 1), pageCount - 1)}
          previousLabel={<CaretLeft size={20} />}
          nextLabel={<CaretRight size={20} />}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
    </div>
  );
};

export default BuyerLienReleasesTable;
