import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import './BuyerPaymentMethods.scss';
import { AppButton } from '../../../general/AppButton/AppButton';
import { Plus, Bank, CreditCard, Warning } from 'phosphor-react';
import AppAddPaymentMethodModal from '../../../general/AppAddPaymentMethodModal/AppAddPaymentMethodModal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { COLORS } from '../../../../utils/colors';
import { DillPaymentMethod, appDelay } from '@dill/dill-shared';
import {
  deletePaymentMethodByMainBuyer,
  getMainBuyerPaymentMethods,
} from '../../../../redux/services/paymentsService';
import AppVerifyDwollaMicroTransactionsModal from '../../../general/AppVerifyDwollaMicroTransactionsModal/AppVerifyDwollaMicroTransactionsModal';
import { useNavigate } from 'react-router-dom';
import { useParamMainBuyerId } from '../../../../utils/hooks/useParamMainBuyerId';
import { Trash } from 'react-feather';
import { closeMessageModal, openMessageModal } from '../../../../redux/globalSlices/genericSlice';
import { getGroupedBuyersSuppliers } from '../../../../redux/services/buyersService';

export const BuyerPaymentMethods = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { paymentMethods } = useAppSelector((state) => state.payments);
  const { user } = useAppSelector((state) => state.auth);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [isOpenActionOption, setOpenActionOptions] = useState('');
  const [isAppAddPaymentMethodModalOpen, setIsAppAddPaymentMethodModalOpen] = useState(false);
  const [
    isAppVerifyDwollaMicroTransactionsModalOpen,
    setIsAppVerifyDwollaMicroTransactionsModalOpen,
  ] = useState(false);
  const [
    selectedPaymentMethodToVerifyMicroTransactions,
    setSelectedPaymentMethodToVerifyMicroTransactions,
  ] = useState<DillPaymentMethod>();

  const toggleDropdown = (value: string) => {
    setOpenActionOptions(value);
  };
  const handleDelete = () => {
    setOpenActionOptions('');
  };
  const handleAddPayment = () => {
    setIsAppAddPaymentMethodModalOpen(true);
  };

  useEffect(() => {
    if (location.pathname.startsWith('/companies')) {
      const searchParams = new URLSearchParams(location.search);
      const paymentMethodId = searchParams.get('paymentMethodId');
      if (paymentMethodId && paymentMethods.length > 0) {
        const paymentMethod = paymentMethods.find((item) => item.id === paymentMethodId);
        if (paymentMethod) {
          setSelectedPaymentMethodToVerifyMicroTransactions(paymentMethod);
          setIsAppVerifyDwollaMicroTransactionsModalOpen(true);
        }
      }
    }
  }, [location.pathname, location.search, paymentMethods]);

  useEffect(() => {
    if (selectedMainBuyerId) {
      dispatch(
        getGroupedBuyersSuppliers({
          mainBuyerId: selectedMainBuyerId,
          weeksLimit: 2,
        })
      );
    }
  }, [selectedMainBuyerId]);

  const handleDeletePaymentMethod = async (paymentMethod: DillPaymentMethod) => {
    dispatch(
      openMessageModal({
        buttonText: 'Cancel',
        isSecondButton: true,
        secondButtonText: 'Delete',
        modalType: 'WARN',
        title: `Are you sure you want to delete this payment method?`,
        subTitle: 'This action can not be undone.',
        onSecondButtonClick: async () => {
          await appDelay(10);
          dispatch(
            openMessageModal({
              buttonText: '',
              modalType: 'LOADING',
              title: 'Deleting',
              subTitle: 'Deleting payment method',
            })
          );
          const deletePaymentMethodResults = await dispatch(
            deletePaymentMethodByMainBuyer({
              mainBuyerId: selectedMainBuyerId || '',
              paymentMethodId: paymentMethod.id,
            })
          );
          if (deletePaymentMethodResults.type === 'payments/deletePaymentMethod/rejected') {
            let errorMessage = '';
            if (deletePaymentMethodResults.type === 'payments/deletePaymentMethod/rejected') {
              errorMessage = deletePaymentMethodResults.payload as any;
            }
            dispatch(
              openMessageModal({
                buttonText: 'Close',
                modalType: 'ERROR',
                title: 'Error deleting payment method',
                subTitle: `${errorMessage} Contact 972-339-0799 for customer support`,
              })
            );
          } else if (deletePaymentMethodResults.type === 'payments/deletePaymentMethod/fulfilled') {
            dispatch(
              openMessageModal({
                buttonText: 'Close',
                modalType: 'SUCCESS',
                title: 'Payment method deleted',
                subTitle: `Payment method deleted successfully`,
                onClose: () => {
                  dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId || '' }));
                },
              })
            );
          } else {
            closeMessageModal();
          }
        },
      })
    );
  };
  return (
    <div className="overflow-y-scroll">
      <AppAddPaymentMethodModal
        open={isAppAddPaymentMethodModalOpen}
        handleClose={() => {
          dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId || '' }));
          setIsAppAddPaymentMethodModalOpen(false);
        }}
        userType="buyer"
      />
      <AppVerifyDwollaMicroTransactionsModal
        selectedPaymentMethod={selectedPaymentMethodToVerifyMicroTransactions}
        open={isAppVerifyDwollaMicroTransactionsModalOpen}
        handleClose={() => {
          dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId || '' }));
          setSelectedPaymentMethodToVerifyMicroTransactions(undefined);
          setIsAppVerifyDwollaMicroTransactionsModalOpen(false);
          navigate(location.pathname);
        }}
      />
      <div className="w-full flex justify-between items-center">
        <div className="title my-2">Payment Methods</div>
        {user?.roles.includes('BUYER') && (
          <div className="">
            <AppButton
              type="PRIMARY"
              buttonStyles={{
                height: '30px',
              }}
              text="Add"
              icon={<Plus color="#FFFFFF" />}
              onClick={() => {
                handleAddPayment();
              }}
            />
          </div>
        )}
      </div>
      <div className="payment-container ">
        {paymentMethods.map((paymentMethod, i) => (
          <div
            className="payment-item-container relative"
            key={`buyer_payment_${paymentMethod.id}`}
            data-tooltip-id={`payment-method-tooltip_${i}`}>
            <div className="absolute right-1.5 top-1.5">
              <Trash
                size={20}
                className="cursor-pointer"
                color={COLORS.ERROR_500}
                onClick={() => {
                  handleDeletePaymentMethod(paymentMethod);
                }}
              />
            </div>

            <div className="w-full flex justify-between">
              <div className="card-container">
                {paymentMethod.type === 'BANK_ACCOUNT' ? (
                  <Bank size={30} style={{ display: 'flex', alignSelf: 'center' }} />
                ) : (
                  <CreditCard size={30} style={{ display: 'flex', alignSelf: 'center' }} />
                )}
              </div>
              {/* <div className="relative">
                <button onClick={() => toggleDropdown(paymentMethod.id)}>
                  <MoreVertical color={COLORS.GREY_300} size={24} />
                </button>
                {isOpenActionOption === paymentMethod.id && (
                  <div className="absolute">
                    <AppButton
                      buttonColor="GREY_200"
                      buttonType="OUTLINED"
                      buttonTextColor="GREY_500"
                      text="Delete"
                      buttonStyles={{
                        padding: '8px',
                        borderWidth: 1,
                        borderColor: COLORS.GREY_200,
                      }}
                      onClick={() => {
                        handleDelete();
                      }}
                    />
                  </div>
                )}
              </div> */}
            </div>
            {paymentMethod.type === 'BANK_ACCOUNT' ? (
              <div>
                <div className="card-title">{paymentMethod.paymentMethodName}</div>
              </div>
            ) : (
              <div>
                <div className="card-title">{paymentMethod.paymentMethodName}</div>
              </div>
            )}
            <div className="">
              {(paymentMethod.status === 'MICRO_DEPOSITS_SENT' ||
                paymentMethod.status === 'MICRO_DEPOSITS_VERIFICATION_PENDING' ||
                paymentMethod.status === 'UNVERIFIED') && (
                <div className="flex-end">
                  <div className="text-xs flex items-center mb-1">
                    <Warning size={18} color={COLORS.ERROR_500} className="mr-1" />
                    Unverified
                  </div>
                  <ReactTooltip
                    id={`payment-method-tooltip_${i}`}
                    place="top"
                    variant="dark"
                    opacity={'100%'}
                    style={{
                      display: 'flex',
                      background: '#334155',
                      width: '300px',
                      zIndex: '70',
                      borderRadius: '8px',
                      alignItems: 'center',
                      justifyItems: 'center',
                      fontWeight: '600',
                    }}>
                    {paymentMethod.status === 'MICRO_DEPOSITS_VERIFICATION_PENDING' ? (
                      <span>
                        Two micro-deposits have arrived in your account. Please check for these
                        deposits in your account and verify them in order to use this bank method.
                      </span>
                    ) : (
                      <span>
                        Two micro-deposits were sent to this bank account. They should arrive 1-2
                        business days from the day it was added. Once you see these deposits in your
                        account, please verify them in order to use this bank method.
                      </span>
                    )}
                  </ReactTooltip>

                  <div className="">
                    <AppButton
                      text="Verify Deposits"
                      isDisabled={paymentMethod.status !== 'MICRO_DEPOSITS_VERIFICATION_PENDING'}
                      onClick={() => {
                        navigate(`${location.pathname}?paymentMethodId=${paymentMethod.id}`);
                        setSelectedPaymentMethodToVerifyMicroTransactions(paymentMethod);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* <AppButton
              buttonColor="PRIMARY_500"
              buttonType="OUTLINED"
              buttonTextColor="PRIMARY_500"
              text="Details"
              buttonStyles={{
                padding: '8px',
                borderWidth: 2,
                borderColor: COLORS.PRIMARY_500,
                width: '100%',
              }}
              onClick={() => {}}
            /> */}
          </div>
        ))}
      </div>
    </div>
  );
};
