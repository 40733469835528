import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  AppUser,
  Buyer,
  DocumentsSummary,
  Supplier,
  SupplierInvoices,
  SupplierSalesReceipts,
} from '@dill/dill-shared';
import {
  addDistributionOneSupplierConnectionExtraReducer,
  addSage100SupplierConnectionExtraReducer,
  assignDriverToInvoiceExtraReducer,
  getSupplierSummaryExtraReducer,
  getUserCustomerExtraReducer,
  getUserCustomersExtraReducer,
  getUserDriversExtraReducer,
  getUserInvoicesExtraReducer,
  getUserSalesReceiptsExtraReducer,
  getUserSuppliersExtraReducer,
  sendEmailStatementsForBuyersExtraReducer,
  sendEmailStatementsForAllBuyersExtraReducer,
  addP21SupplierConnectionExtraReducer,
} from '../reducers/suppliersReducers';

export interface SuppliersInitialState {
  isLoading: boolean;
  loadingList: string[];
  error: string | null;
  suppliers: Supplier[];
  selectedSupplier: Supplier | null;
  supplierTotalDocumentsSummary: DocumentsSummary | null;
  // customers: {
  //   buyer: Buyer;
  //   usersEmailsDetails: {
  //     email: string;
  //     isStatementsEmailOptOut: boolean;
  //     isPaymentRemindersEmailOptOut: boolean;
  //     isInvitedUser: boolean;
  //   }[];
  //   invoicesSummary: { pastDue: number; currentDue: number; credits: number };
  // }[];
  supplierDrivers: AppUser[];
  userSupplierInvoices: SupplierInvoices | null;
  userSupplierInvoicesDataWeeksLimit: number;
  userSupplierSalesReceipts: SupplierSalesReceipts | null;
  userSupplierSalesReceiptsDataWeeksLimit: number;
}

const initialState: SuppliersInitialState = {
  isLoading: false,
  loadingList: [],
  error: null,
  supplierTotalDocumentsSummary: null,
  suppliers: [],
  // customers: [],
  supplierDrivers: [],
  selectedSupplier: null,
  userSupplierInvoices: null,
  userSupplierInvoicesDataWeeksLimit: 2,
  userSupplierSalesReceipts: null,
  userSupplierSalesReceiptsDataWeeksLimit: 2,
};

export const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    resetSuppliersRootState: () => initialState,
    selectSupplier: (state, action: PayloadAction<Supplier | null>) => {
      state.selectedSupplier = action.payload;
    },
    updateUserSupplierInvoicesDataWeeksLimit: (state, action: PayloadAction<number>) => {
      state.userSupplierInvoicesDataWeeksLimit = action.payload;
    },
    updateUserSupplierSalesReceiptsDataWeeksLimit: (state, action: PayloadAction<number>) => {
      state.userSupplierSalesReceiptsDataWeeksLimit = action.payload;
    },
  },
  extraReducers(builder) {
    getUserSuppliersExtraReducer(builder);
    getUserCustomersExtraReducer(builder);
    getSupplierSummaryExtraReducer(builder);
    getUserInvoicesExtraReducer(builder);
    getUserDriversExtraReducer(builder);
    assignDriverToInvoiceExtraReducer(builder);
    getUserSalesReceiptsExtraReducer(builder);
    sendEmailStatementsForBuyersExtraReducer(builder);
    addDistributionOneSupplierConnectionExtraReducer(builder);
    addSage100SupplierConnectionExtraReducer(builder);
    addP21SupplierConnectionExtraReducer(builder);
    sendEmailStatementsForAllBuyersExtraReducer(builder);
    getUserCustomerExtraReducer(builder);
  },
});

export const {
  resetSuppliersRootState,
  selectSupplier,
  updateUserSupplierInvoicesDataWeeksLimit,
  updateUserSupplierSalesReceiptsDataWeeksLimit,
} = suppliersSlice.actions;
