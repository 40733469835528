import { useState, useEffect, useMemo } from 'react';
import { Plus, Search } from 'react-feather';
import { AppButton } from '../../../general/AppButton/AppButton';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import { AppErrorPage } from '../../../general/AppErrorPage/AppErrorPage';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { COLORS } from '../../../../utils/colors';
import {
  getSupplierJobs,
  recursiveGetSupplierJobs,
} from '../../../../redux/services/supplierJobsService';
import { SupplierJobApiResponse } from '@dill/dill-shared/dist/src';
import SupplierJobsTable from '../SupplierJobsTable/SupplierJobsTable';
import { AppNewSupplierJobModal } from '../../../general/AppNewSupplierJobModal/AppNewSupplierJobModal';
import { getSupplierBuyers } from '../../../../redux/services/supplierBuyersService';
import { getSupplierLienTemplates } from '../../../../redux/services/leinTemplatesService';
import { getUserSignatures } from '../../../../redux/services/authService';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { AppLoadingProgress } from '../../../general/AppLoadingProgress/AppLoadingProgress';
import { removeExtraSpaces } from '../../../../utils/helpers';
import { resetSupplierJobs } from '../../../../redux/globalSlices/supplierJobsSlice';
import { AppBulkEditSupplierJobModal } from '../../../general/AppNewSupplierJobModal/AppBulkEditSupplierJobModal';

const SupplierJobs = () => {
  const dispatch = useAppDispatch();
  const [addNewJob, setAddNewJob] = useState(false);
  const [bulkEditJob, setBulkEditJob] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [toogle, setToogle] = useState(true);
  const [supplierJobsCount, setSupplierJobsCount] = useState(0);

  const { loadingErrorList, supplierJobs, selectedSupplierJobs } = useAppSelector(
    (state) => state.supplierJobs
  );
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const { userSignatures } = useAppSelector((state) => state.auth);
  const [sortOptions, setSortOptions] = useState<{ sortOrder: 'asc' | 'desc'; sortBy: string }>({
    sortOrder: 'desc',
    sortBy: 'createdAt',
  });
  const [previousLastDocumentId, setPreviousLastDocumentId] = useState('');
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSupplierJobs, setCurrentSupplierJobs] = useState<SupplierJobApiResponse[]>([]);
  const [dataFullyLoaded, setDataFullyLoaded] = useState<boolean>(false);

  const searchedSupplierJobs: SupplierJobApiResponse[] = useMemo(() => {
    let filteredSupplierJobs: SupplierJobApiResponse[] = [];
    const activeJobs = supplierJobs.filter((job) => {
      if (toogle && job?.archivedState && job?.archivedState.includes('SUPPLIER_ARCHIVED')) {
        return false;
      }
      return true;
    });
    if (activeJobs && searchText) {
      const searched = activeJobs.filter((supplierJob) => {
        const updateSearchedText = removeExtraSpaces(searchText);
        if (
          (supplierJob.name &&
            removeExtraSpaces(supplierJob.name)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.jobNumber &&
            supplierJob.jobNumber
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.buyer?.name &&
            supplierJob.buyer?.name
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.buyer?.address &&
            removeExtraSpaces(supplierJob.buyer?.address)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.buyer?.city &&
            removeExtraSpaces(supplierJob.buyer?.city)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.buyer?.state &&
            removeExtraSpaces(supplierJob.buyer?.state)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.buyer?.zipCode &&
            removeExtraSpaces(supplierJob.buyer?.zipCode)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.owner &&
            removeExtraSpaces(supplierJob.owner)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.ownerAddress &&
            removeExtraSpaces(supplierJob.ownerAddress)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.ownerCity &&
            removeExtraSpaces(supplierJob.ownerCity)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.ownerState &&
            removeExtraSpaces(supplierJob.ownerState)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.ownerZipCode &&
            removeExtraSpaces(supplierJob.ownerZipCode)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.jobAddress &&
            removeExtraSpaces(supplierJob.jobAddress)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.jobCity &&
            removeExtraSpaces(supplierJob.jobCity)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.jobState &&
            removeExtraSpaces(supplierJob.jobState)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.jobZipCode &&
            removeExtraSpaces(supplierJob.jobCity)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.lender &&
            removeExtraSpaces(supplierJob.lender)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.lenderAddress &&
            removeExtraSpaces(supplierJob.lenderAddress)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.lenderCity &&
            removeExtraSpaces(supplierJob.lenderCity)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.lenderState &&
            removeExtraSpaces(supplierJob.lenderState)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.lenderZipCode &&
            removeExtraSpaces(supplierJob.lenderZipCode)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase()))
        ) {
          return true;
        }
        return false;
      });
      filteredSupplierJobs = searched;
    } else {
      filteredSupplierJobs = activeJobs;
    }

    return filteredSupplierJobs;
  }, [supplierJobs, searchText, toogle]);

  const error = useMemo(() => {
    if (loadingErrorList.length > 0) {
      const errorMessage: any = loadingErrorList.filter((obj) =>
        obj.hasOwnProperty('getSupplierJobs')
      );
      if (errorMessage.length > 0) {
        return errorMessage[0]?.getSupplierJobs;
      }
    }
    return null;
  }, [loadingErrorList]);

  const recursiveFetchSupplierJob = async (
    count: number,
    lastDockId: string,
    fetchedJobs: SupplierJobApiResponse[]
  ) => {
    if (supplierJobs.length > count) {
      setDataFullyLoaded(true);
      return;
    }
    setSupplierJobsCount(count);
    const pageSize = 100;
    let currentPreviousLastDocumentId = lastDockId;
    for (let i = fetchedJobs.length; i < count; i += pageSize) {
      const results = await dispatch(
        recursiveGetSupplierJobs({
          includeInvoices: true,
          includePreLiens: true,
          previousLastDocumentId: currentPreviousLastDocumentId,
          pageSize,
          sortBy: sortOptions.sortBy,
          sortOrder: sortOptions.sortOrder,
        })
      );
      if (results.type === 'supplierJobs/recursiveGetSupplierJobs/fulfilled') {
        if (results.payload) {
          const payload = results.payload as any;
          const fetchedSupplierJobs: SupplierJobApiResponse[] = payload.data as any;
          const lastDocumentId = payload.lastDocumentId;
          if (lastDocumentId !== currentPreviousLastDocumentId) {
            currentPreviousLastDocumentId = lastDocumentId;
          }
          if (!lastDockId || fetchedSupplierJobs.length < pageSize) {
            console.log('No more pages to fetch');
            setDataFullyLoaded(true);
            break;
          }
        }
      } else {
        console.log('Fetch failed or interrupted');
        break;
      }
    }
  };

  const handleLoadMore = async (loadOptions?: { isFreshFetch?: boolean }) => {
    setLoading(true);
    setDataFullyLoaded(false);
    const pageSize = 50;
    let currentPreviousLastDocumentId = previousLastDocumentId;
    let newSupplierJobs = currentSupplierJobs;
    if (loadOptions && loadOptions?.isFreshFetch) {
      currentPreviousLastDocumentId = '';
      newSupplierJobs = [];
    }
    const results = await dispatch(
      getSupplierJobs({
        includeInvoices: true,
        includePreLiens: true,
        previousLastDocumentId: currentPreviousLastDocumentId,
        pageSize,
        sortBy: sortOptions.sortBy,
        sortOrder: sortOptions.sortOrder,
      })
    );
    setLoading(false);
    if (results.type === 'supplierJobs/getSupplierJobs/fulfilled') {
      if (results.payload) {
        const payload = results.payload as any;
        const fetchedSupplierJobs: SupplierJobApiResponse[] = payload.data as any;
        const lastDockId = payload.lastDocumentId;

        if (lastDockId && lastDockId !== currentPreviousLastDocumentId) {
          setPreviousLastDocumentId(lastDockId);

          if (payload.count) {
            const count = Number(payload.count);
            if (count <= fetchedSupplierJobs.length) {
              setDataFullyLoaded(true);
            }
            recursiveFetchSupplierJob(count, lastDockId, [
              ...newSupplierJobs,
              ...fetchedSupplierJobs,
            ]);
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(resetSupplierJobs());
    handleLoadMore({ isFreshFetch: true });
    dispatch(getSupplierBuyers());
    if (lienTemplates.length === 0) {
      dispatch(getSupplierLienTemplates());
    }
    if (userSignatures.length) {
      dispatch(getUserSignatures());
    }
  }, []);

  const [infiniteRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <div className="h-full p-8 flex ">
      <AppNewSupplierJobModal
        open={addNewJob}
        handleClose={() => {
          setAddNewJob(false);
        }}
        onReload={() => {
          handleLoadMore({ isFreshFetch: true });
        }}
      />
      <AppBulkEditSupplierJobModal
        open={bulkEditJob}
        handleClose={() => {
          setBulkEditJob(false);
        }}
      />
      <div className="flex flex-col px-5 py-4 w-full bg-white">
        <div className="flex justify-between">
          <div className="flex items-center">
            <AppInputField
              inputFieldStyles={{ width: '300px' }}
              id="search"
              value={searchText}
              placeholder={'Search '}
              icon={<Search size={15} />}
              onTextChange={(text) => {
                setSearchText(text);
              }}
            />
            <div className="flex ml-2">
              <div
                onClick={() => setToogle(!toogle)}
                className={`w-[40px] h-[22px] rounded-full p-[1px] grid flex-col cursor-pointer ${
                  toogle ? 'justify-items-end bg-[#3862fb]' : 'GREY_500-BG'
                }`}>
                <div className="bg-white w-[20px] h-[20px] rounded-full" />
              </div>
              <div className="w-[180px] ml-2 font-semibold">Show Only Active Jobs</div>
            </div>
          </div>
          <div className="flex items-center">
            <AppLoadingProgress
              text="Supplier Job"
              currentCount={supplierJobs.length}
              totalCount={supplierJobsCount}
              showTotalLoading={true}
            />
            {selectedSupplierJobs.length > 0 && (
              <AppButton
                type="SECONDARY"
                text="Bulk Edit"
                onClick={() => setBulkEditJob(true)}
                buttonStyles={{ marginLeft: '8px', height: '44px' }}
              />
            )}
            <AppButton
              text="Add New Job"
              icon={<Plus color={COLORS.WHITE} />}
              onClick={() => setAddNewJob(true)}
              buttonStyles={{ marginLeft: '8px' }}
            />
          </div>
        </div>
        {error ? (
          <AppErrorPage
            title="Error loading  jobs."
            contactMessage="Please contact customer support."
            errorMessage={error}
          />
        ) : (
          <SupplierJobsTable
            searchedSupplierJobs={searchedSupplierJobs}
            setSupplierJobs={setCurrentSupplierJobs}
            infiniteRef={infiniteRef}
            hasNextPage={false}
            loading={loading}
            sortOptions={sortOptions}
            onReload={() => {
              // handleLoadMore({ isFreshFetch: true });
            }}
            onSortChange={({ sortBy, sortOrder }) => {
              setSortOptions({ sortBy, sortOrder });
            }}
            dataFullyLoaded={dataFullyLoaded}
          />
        )}
      </div>
    </div>
  );
};

export default SupplierJobs;
