import { createSlice } from '@reduxjs/toolkit';
import { getP21InvoicesExtraReducer } from '../reducers/p21Reducers';

export interface P21InitialState {
  loadingList: string[];
  p21Invoices: any[];
}

const initialState: P21InitialState = {
  loadingList: [],
  p21Invoices: [],
};

export const p21Slice = createSlice({
  name: 'p21',
  initialState,
  reducers: {
    resetP21RootState: () => initialState,
  },
  extraReducers(builder) {
    getP21InvoicesExtraReducer(builder);
  },
});

export const { resetP21RootState } = p21Slice.actions;
